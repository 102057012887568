import { CheckGroup, FormGroup, Label, Input, DyInputTime, DyInput } from './styled';
import React, { useState, useEffect } from 'react';
import MultiSelecter from '../MultiSelecter';
import CustomSelectBox from '../SearchSelecter';
import styled from 'styled-components';
const DynamicSimpleSearch = ({ field, value, onChange, isLable }) => {
  const [options, setOptions] = useState([]);
  const [arraySelectOptions, setArraySelectOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let url;
        let response = {};

        if (field?.control_type === 'SelectBox' && field.bind_key) {
          if (field.bind_type === 'M') {
            url = `/request/getFormMasterData?bind_key=${field.bind_key}&bind_option=${field.bind_option}&bind_display=${field.bind_display}`;
          } else if (field.bind_type === 'O') {
            url = `/request/getFormControlOptions?bind_key=${field.bind_key}&bind_option=${field.bind_option}`;
          } else if (field.bind_type === 'S' || field.bind_type === 'C') {
            url = `/request/getFormControlOptions?bind_key=${field.bind_key}`;
          } else {
            url = `/request/getFormControlOptions?bind_key=${field.bind_key}`;
          }
          response = await fetch(url, {
            credentials: 'include',
            headers: {
              Accept: 'application/json',
            },
            method: 'GET',
          });
        }

        if (response.ok) {
          const data = await response.json();
          setOptions(data);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [field]); // Remove `rootColumnValue` dependency

  useEffect(() => {
    const arrayInputSelect = async () => {
      try {
        const response = await fetch(
          `/request/getOptions?target_code=${field.bind_key}&code_group=${field.bind_key}`,
          { credentials: 'include' }
        );
        if (response.ok) {
          const data = await response.json();
          const formattedOptions = data.options.map((item) => ({
            value: item.code_value, // Use code_value as value
            label: '[ ' + item.code_option + ' | ' + item.code_value + ' ]', // Use code_option as label
          }));
          setArraySelectOptions(formattedOptions);
        } else {
          console.error('Failed to fetch options');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    arrayInputSelect();
  }, [field]); // Remove `rootColumnValue` dependency

  // 스타일 커스터마이징: 선택된 값이 태그로 표시되지 않도록 설정
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '40px',
      zIndex: 1,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      overflow: 'hidden',
    }),
    multiValue: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 101,
    }),
  };
  const SelectHeader = styled.div`
  border: 1px solid #ddd;
  padding: 7px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size:12px;
`;
  const handleChange = (e) => {
    onChange(field?.column_id, e.target.value);
  };

  if (field?.column_id === 'title' || field?.column_id === 'content') return null;

  const gridStyles = {
    gridColumnStart: field?.ctrl_pos_x || 'auto',
    gridColumnEnd: field?.ctrl_pos_x ? `span ${field.ctrl_width || 1}` : 'auto',
    gridRowStart: field?.ctrl_pos_y || 'auto',
    gridRowEnd: field?.ctrl_pos_y ? `span ${field.ctrl_height || 1}` : 'auto',
    display: 'flex',
    flexDirection: 'column',
  };

  switch (field?.control_type) {
    case 'NumberBox':
      return (
        <div style={gridStyles} id='dy-textBox'>
          {isLable && <Label>{field.column_title}</Label>}
          <DyInput type='number' value={value} onChange={handleChange} />
        </div>
      );
    case 'TextBox':
      return (
        <div style={gridStyles} id='dy-textBox'>
          {isLable && <Label>{field.column_title}</Label>}
          <DyInput type='text' value={value} onChange={handleChange} />
        </div>
      );
    case 'DateTimeBox':
      return (
        <FormGroup style={gridStyles}>
          {isLable && <Label>{field.column_title}</Label>}
          <DyInputTime type='date' value={value} onChange={handleChange} />
        </FormGroup>
      );
      case 'TimeBox':
        return (
          <FormGroup style={gridStyles}>
             {isLable && <Label>{field.column_title}</Label>}
            <DyInputTime type="time" value={value} onChange={handleChange} />
          </FormGroup>
        );
      case 'DateBox':
        return (
          <FormGroup style={gridStyles}>
              {isLable && <Label>{field.column_title}</Label>}
            <DyInputTime type="date" value={value} onChange={handleChange} />
          </FormGroup>
        );
    case 'SelectBox':
      return (
        <FormGroup style={gridStyles}>
          {isLable && <Label>{field.column_title}</Label>}
          <CustomSelectBox
            value={value}
            placeholder={'검색조건을 선택하세요'}
            onChange={handleChange}
            options={options}
            field={field}
            isSearch={field?.mask?.includes("Search")}
            isCancel={field?.mask?.includes("Cancel")}
            SelectHeader={SelectHeader}
          />
        </FormGroup>
      );
    case 'CheckBox':
      return (
        <CheckGroup id='test' style={gridStyles}>
          {isLable && <Label>{field.column_title}</Label>}
          <div
            id='wrap'
            style={{
              height: '100%',
              alignSelf: 'center',
              alignContent: 'center',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div
              className='check'
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignContent: 'center',
              }}
            >
              <section
                style={{
                  justifyContent: 'center',
                  background: 'white',
                  display: 'flex',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  padding: '8px',
                  height: '1.5rem',
                  borderRadius: '5px',
                  width: '100%',
                  borderColor: 'silver',
                  alignItems: 'center',
                }}
              >
                {/* 체크박스 */}
                <Input
                  style={{ width: '1.3rem' }}
                  type='checkbox'
                  checked={value}
                  onChange={(e) => onChange(field.column_id, e.target.checked)}
                />
                {isLable && (
                  <Label
                    style={{
                      alignSelf: 'center',
                      marginLeft: '5px',
                      marginTop: '5px',
                      fontSize: '0.9rem',
                    }}
                  >
                    {field.column_title}
                  </Label>
                )}

                {/* 'X' 버튼: 값이 있을 때만 렌더링 */}
                {value && (
                  <button
                    style={{
                      marginLeft: '10px',
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: 'red',
                      fontSize: '1rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => onChange(field.column_id, undefined)}
                    aria-label='Clear checkbox'
                  >
                    &#x2716;
                  </button>
                )}
              </section>
            </div>
          </div>
        </CheckGroup>
      );
    case 'ArrayInput':
      const ArrayInputSelectHeader = styled.div`
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width:100%;
      `;
      return (
        <FormGroup style={gridStyles}>
          {isLable && <Label>{field.column_title}</Label>}
          <MultiSelecter
            options={arraySelectOptions}
            value={value}
            onChange={(newValue) => onChange(field.column_id, newValue)}
            placeholder={field.column_title}
            SelectHeader={ArrayInputSelectHeader}
          />
        </FormGroup>
      );
    default:
      return (
        <div style={gridStyles} id='dy-textBox'>
          {isLable && <Label>{field.column_title}</Label>}
          <DyInput type='text' value={value} onChange={handleChange} />
        </div>
      );
  }
};

export default DynamicSimpleSearch;
