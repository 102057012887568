import React, { useState, useEffect, useRef } from "react";

const ArrayButtonCell = ({
  column,
  board,
  isEditMode,
  handleButtonClick,
  isPopupVisible,
  onPopupToggle,
}) => {
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);

  const handleMouseEnter = (e) => {
    const { clientX, clientY } = e;

    // `position: fixed`를 사용할 때는 스크롤 값을 더하지 않습니다.
    setPopupPosition({ x: clientX, y: clientY });
    onPopupToggle();
  };

  const handlePopupClose = () => {
    onPopupToggle();
  };

  useEffect(() => {
    if (isPopupVisible && popupRef.current) {
      adjustPopupPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupVisible]);

  const adjustPopupPosition = () => {
    if (popupRef.current) {
      const popupRect = popupRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let newX = popupPosition.x;
      let newY = popupPosition.y;

      // 오른쪽 경계를 넘어갈 경우
      if (popupPosition.x + popupRect.width + 10 > viewportWidth) {
        newX = viewportWidth - popupRect.width - 10;
      }

      // 아래쪽 경계를 넘어갈 경우
      if (popupPosition.y + popupRect.height + 10 > viewportHeight) {
        newY = viewportHeight - popupRect.height - 150;
      }

      // 왼쪽 경계를 넘어갈 경우
      if (newX < 10) newX = 10;

      // 위쪽 경계를 넘어갈 경우
      if (newY < 10) newY = 10;

      setPopupPosition({ x: newX, y: newY });
    }
  };

  let functionArray = [];
  try {
    functionArray = new Function(`return ${column.function}`)();
  } catch (error) {
    console.log("Invalid function format in functionArray: " + error.message);
  }

  let actionArray = [];
  try {
    actionArray = new Function(`return ${column.result_action}`)();
  } catch (error) {
    console.log("Invalid function format in actionArray: " + error.message);
  }

  return (
    <div style={{ position: "relative" }}>
      <button
        style={{ background: "white", width: "100%" }}
        disabled={isEditMode}
        onClick={handleMouseEnter}
      >
        {column.column_title}
      </button>

      {isPopupVisible && (
        <div
          ref={popupRef}
          style={{
            position: "fixed",
            top: popupPosition.y + 10,
            left: popupPosition.x + 10,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000,
            //overflow: "auto",
          }}
        >
          {Array.isArray(functionArray) &&
            functionArray.map((funcObj, idx) => {
              const resultObj = actionArray.find(
                (result) => result.exec_func === funcObj.function
              );
              return (
                <div key={idx}>
                  <button
                    style={{
                      marginRight: "5px",
                      display: "block",
                      marginBottom: "5px",
                    }}
                    disabled={isEditMode}
                    onClick={() =>{
                      handleButtonClick(
                        funcObj.function,
                        board,
                        resultObj?.result_action
                      );
                    }
                    }
                  >
                    {funcObj.button_txt || `${column.column_title} ${idx + 1}`}
                  </button>
                </div>
              );
            })}

          {/* 팝업 닫기 버튼 */}
          <button onClick={handlePopupClose}>닫기</button>
        </div>
      )}
    </div>
  );
};

export default ArrayButtonCell;