import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  couponSetExport,
  statisticExport,
  pw_change,
  member_delete,
} from "../clientAction";
import "react-datepicker/dist/react-datepicker.css";
import { faSave, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import CustomSelectBox from "../../../../components/SearchSelecter";
import ArrayButtonCell from "./ArrayButtonCell.js";
import LongTextPopup from "../../../../components/LongTextPopup";
import PasswordChangePopup from "../../../PasswordChangePopup";
import usePasswordChange from "../../../PasswordChangePopup/usePasswordChange.js";
import { useNavigate } from "react-router-dom";
import { encrypt, decrypt } from "../../../Security.js";
import styled from "styled-components";
import dayjs from "dayjs";
import {
  StyledTable,
  StyledThead,
  StyledTh,
  StyledTbody,
  StyledTr,
  StyledTd,
  Input,
  FileIcon,
  InputDateTime,
} from "../styled";
import MultiSelecter from "../../../MultiSelecter/index.js";

export function BoardsTable({
  tableName,
  onRefresh,
  setSelectedRows,
  setIsShowDynamicPopup,
  setOpenPost,
  boards,
  columns,
  selectedRows,
  toggleRowSelection,
  isEditMode,
  editedRows,
  handleRowChange,
  saveMessage,
  codeDisplayMap,
  isAllSelected,
  setIsAllSelected,
  setIsOpenViewer,
  isOpenViewer,
  refreshFlag,
  setRefreshFlag,
  searchParams,
  setPopupType,
}) {
  const {
    showPasswordChangePopup,
    handleOpenPasswordChangePopup,
    handleClosePasswordChangePopup,
    handlePasswordChangeSubmit,
  } = usePasswordChange();
  const [selectBoxOptions, setSelectBoxOptions] = useState({});
  const [arraySelectBoxOptions, setArraySelectBoxOptions] = useState({});
  const [openPopupRow, setOpenPopupRow] = useState(null); // 현재 팝업이 열려 있는 행을 추적하는 상태
  const [from, setFrom] = useState(searchParams.from);
  const [to, setTo] = useState(searchParams.to);
  const navigate = useNavigate();
  //팝업창을위한 포지션체크
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const handleMouseEnter = (e, index) => {
    const { clientX, clientY } = e;

    // `position: fixed`를 사용할 때는 스크롤 값을 더하지 않습니다.
    setPopupPosition({ x: clientX, y: clientY });
    setOpenPopupRow(openPopupRow === index ? null : index);
  };

  const handlePopupClose = (index) => {
    setOpenPopupRow(openPopupRow === index ? null : index);
  };

  useEffect(() => {
    boards.forEach((_, index) => {
      if (selectedRows.includes(index)) {
        toggleRowSelection(index, boards[index], false);
      }
    });
    setIsAllSelected(false);
  }, [boards, refreshFlag]);
  useEffect(() => {
    setFrom(searchParams?.from);
    setTo(searchParams?.to);
    //console.log(searchParams.from);
    //console.log(searchParams.to);
  }, [searchParams]);

  useEffect(() => {
    const fetchSelectBoxData = async (
      bind_key,
      column_id,
      bind_type,
      bind_option,
      bind_display
    ) => {
      try {
        //console.log('바인딩 파라미터:'+bind_type+bind_option+bind_display);
        // M 유형인 경우 다른 URL 또는 파라미터 설정
        const url =
          bind_type === "M"
            ? `/request/getFormMasterData?bind_key=${bind_key}&bind_option=${bind_option}&bind_display=${bind_display}` // M 유형에 맞는 URL
            : `/request/getFormControlOptions?bind_key=${bind_key}`; // S와 C 유형에 맞는 URL

        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) throw new Error("Failed to fetch select box options");

        const data = await response.json();
        setSelectBoxOptions((prevState) => ({
          ...prevState,
          [column_id]: data,
        }));

        setArraySelectBoxOptions((prevState) => ({
          ...prevState,
          [column_id]: data.map((item) => ({
            label: item.std_key,
            value: item.std_value,
          })),
        }));
      } catch (error) {
        console.error("Error fetching SelectBox options:", error);
      }
    };

    columns.forEach((column) => {
      if (
        (column.control_type === "ArraySelectBox" ||
          column.control_type === "SelectBox") &&
        ["S", "C", "M"].includes(column.bind_type)
      ) {
        fetchSelectBoxData(
          column.bind_key,
          column.column_id,
          column.bind_type,
          column.bind_option,
          column.bind_display
        );
      }
    });
  }, [columns]);
  // Define the handleInputChange function
  // 특정 행을 선택하는 함수 정의
  const handleRowData = (rowData) => {
    // 여기서 rowData를 활용하여 원하는 작업을 수행합니다.
    //console.log('클릭된 행의 데이터:', rowData);
    // 예: 팝업을 열고 데이터 표시, 상태 업데이트 등
  };
  // Function to handle button click and perform API fetch
  const handleButtonClick = async (columnFunction, board, result_action) => {
    //console.log(JSON.stringify(board));
    setOpenPopupRow(null);
    const apiParameters = await fetchApiParameters(columnFunction);
    if (!apiParameters) {
      alert("Failed to fetch API parameters. Please try again later.");
      return;
    }

    const row = board;
    const api_url =
      apiParameters && apiParameters[0] && apiParameters[0].std_description
        ? apiParameters[0].std_description
        : null;
    if (api_url !== null) {
      //console.log(api_url);
    }

    let url;
    try {
      url = new URL(api_url);
    } catch {
      alert("API URL이 비정상입니다." + api_url);
      return;
    }

    // Prepare request options
    const options = {
      credentials: "include",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // Create the request body with separate row and param objects
    const requestBody = {
      parameters: apiParameters,
      row: row,
    };
    options.body = JSON.stringify(requestBody);
    // Perform fetch call
    try {
      //console.log('Making API call to:', url); // Debugging log
      const response = await fetch(url, options);
      //console.log(JSON.stringify(result));
      const result = await response.json();
      if (result_action) {
        callResultFunc(result_action, result);
      }
      // Now send the result to the InterfaceResultTable endpoint
      await storeResultInDatabase(result);
      //console.log('API call was successful.');
    } catch (error) {
      console.error("API call failed:", error);
      console.log(
        "API call failed. Please check the console for more details." + error
      );
    }
  };
  const storeResultInDatabase = async (result) => {
    const saveUrl = "/request/saveResult"; // Replace with your server's endpoint

    try {
      const response = await fetch(saveUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ result }),
      });

      if (response.ok) {
        // console.log('.');
      } else {
        console.log("Failed to save result to the database.");
      }
    } catch (error) {
      console.error("Failed to save result:", error);
      console.log(
        "Failed to save result. Please check the console for more details."
      );
    }
  };

  const fetchApiParameters = async (columnFunction) => {
    try {
      const url = new URL("/request/getApiParameters", window.location.origin);
      url.searchParams.append("target_code", columnFunction);
      const response = await fetch(url, {
        credentials: "include",
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.log("Failed to fetch API parameters: " + error.message);
      return null;
    }
  };

  const getUserInfo = async (user_id) => {
    try {
      const response = await fetch("/request/getPassword", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      //alert("Response data:", data);  // Add this line
      return data;
    } catch (error) {
      console.log("Failed to fetch API parameters: " + error.message);
      return null;
    }
  };

  const callResultFunc = async (action, result) => {
    switch (action) {
      case "req_sms_send":
        //alert('문자전송 완료');
        break;
      case "execel_down":
        couponSetExport(result);
        break;
      case "statistic_down":
        statisticExport(result);
        break;
      case "pw_change":
        handleOpenPasswordChangePopup(result); // 팝업 열기 함수 호출
        break;
      case "member_delete":
        //console.log(result);
        //member_delete(row);
        onRefresh();
        break;
      case "loginCb":
        // JSON.stringify로 출력 후 삭제
        let phoneNumber = "";
        let password = "";
        let resultPW = "";
        if (tableName === "personal_info_mgmt") {
          phoneNumber = result.phone_number;
          password = decrypt(result.password, "ANTS");
        } else {
          phoneNumber = result.user_id;
          resultPW = await getUserInfo(result.user_id); // await 추가
          password = decrypt(resultPW.hashedPassword, "ANTS"); // await 추가
        }
        // URL에 phone_number와 password를 쿼리 파라미터로 추가
        let url = `https://cinema.skynet.re.kr/autoLogin?phone_number=${encodeURIComponent(
          phoneNumber
        )}&password=${encodeURIComponent(password)}`;
        // 새로운 창을 열어서 자동 로그인을 시도
        window.open(
          url,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600"
        );
        break;
      case "mobileLoginCb": //mobileLoginCb
        let mphoneNumber = "";
        let mpassword = "";
        let MresultPW = "";
        if (tableName === "personal_info_mgmt") {
          mphoneNumber = result.phone_number;
          mpassword = decrypt(result.password, "ANTS");
        } else {
          mphoneNumber = result.user_id;
          MresultPW = await getUserInfo(result.user_id); // await 추가
          mpassword = decrypt(MresultPW.hashedPassword, "ANTS");
        }
        let mobile_url = `https://cinema.skynet.re.kr/autoLogin?phone_number=${encodeURIComponent(
          mphoneNumber
        )}&password=${encodeURIComponent(mpassword)}`;
        // 새로운 창을 열어서 자동 로그인을 시도
        window.open(
          mobile_url,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600"
        );
        break;
      case "viewInfoCb": //viewInfoCb
        if (tableName === "personal_info_mgmt") {
          setPopupType("UserInfoView", result);
        } else {
          setPopupType("UserInfoView", {
            name: result.user_name,
            phone_number: result.user_id,
          });
        }
        setIsShowDynamicPopup(true);
        break;
      case "viewRefundsCb": //viewRefundsCb
        navigate("/Order/Board/refund_req", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name }
              : { user_name: result.user_name },
        });
        break;
      case "viewRewardsCb": //viewRewardsCb
        navigate("/Savings/Board/used_point", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name }
              : { user_name: result.user_name },
        });
        break;
      case "viewOrdersCb": //viewOrdersCb
        navigate("/Order/Board/order_list", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name }
              : { user_name: result.user_name },
        });
        break;
      case "viewCouponsCb": //viewCouponsCb
        //navigate('/Coupon/Board/coupon_total',{ state: { result: result.name} }); // Navigate to purchase history page
        navigate("/Coupon/Board/coupon_total", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name, phone_number: result.phone_number }
              : {
                  user_name: result.user_name,
                  phone_number: result.phone_number,
                },
        }); //phone_number: result.phone_number
        break;
      case "verifyCouponCb": //verifyCouponCb
        setPopupType("VerifyCouponView", result); //isShowDynamicPopup
        setIsShowDynamicPopup(true);
        break;
      case "viewDepositsCb": //viewDepositsCb
        navigate("/Order/Board/deposit_list", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name }
              : { user_name: result.user_name },
        });
        break;
      case "viewPurchasesCb": //
        navigate("/Product/Board/product_reg", {
          state:
            tableName === "personal_info_mgmt"
              ? { user_name: result.name }
              : { user_name: result.user_name },
        });
        break;
      default:
        break;
    }
  };
  const handleHeaderCheckboxChange = (e) => {
    const newIsAllSelected = e.target.checked;
    setIsAllSelected(newIsAllSelected);

    boards.forEach((_, index) => {
      toggleRowSelection(index, boards[index], newIsAllSelected);
    });
  };
  const SelectHeader = styled.div`
    border: 1px solid #ddd;
    padding: 1px 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  `;
  const renderSelectBox = (column, board, index) => (
    <CustomSelectBox
      value={
        editedRows[index]?.[column.column_id] ?? board[column.column_id] ?? ""
      }
      onChange={(e) =>
        handleRowChange(index, column.column_id, e.target.value, board)
      }
      options={selectBoxOptions[column.column_id]}
      field={columns}
      disabled={column.read_only}
      SelectHeader={SelectHeader}
    />
  );

  const handleArraySelectChange = (index, column, newValue, board) => {
    // 배열의 값을 문자열 배열 형식으로 변환
    const formattedValue = newValue.map((value) => `${value}`).join(", ");

    //console.log('Formatted newValue:', formattedValue); // 배열 형태의 문자열 출력

    handleRowChange(index, column, formattedValue, board); // 부모 컴포넌트로 변경 알림 (배열 형태의 문자열 전달)
  };
  const MultiSelecterSelectHeader = styled.div`
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `;
  const ArraySelectBox = (column, board, index) => (
    <div>
      {/* {JSON.stringify(arraySelectBoxOptions[column.column_id])} */}
      <MultiSelecter
        value={
          editedRows[index]?.[column.column_id] ?? board[column.column_id] ?? ""
        }
        onChange={(newValue) =>
          handleArraySelectChange(index, column.column_id, newValue, board)
        }
        options={arraySelectBoxOptions[column.column_id]}
        field={columns}
        disabled={column.read_only}
        SelectHeader={MultiSelecterSelectHeader}
      />
    </div>
  );

  const renderButton = (column, board) => (
    <button
      style={{ background: "white" }}
      disabled={isEditMode}
      onClick={() =>
        handleButtonClick(column.function, board, column.result_action)
      }
    >
      {column.function === null ? "함수 미설정" : column.column_title}
    </button>
  );

  const renderArrayButton = (column, board, index) => (
    <ArrayButtonCell
      column={column}
      board={board}
      isEditMode={isEditMode}
      handleButtonClick={handleButtonClick}
      isPopupVisible={openPopupRow === index} // Check if the popup should be visible for the current row
      onPopupToggle={() =>
        setOpenPopupRow(openPopupRow === index ? null : index)
      }
    />
  );
  const renderDateTimeBox = (column, board, index) => {
    const isDate = column.control_type === "DateBox";
    const isTime = column.control_type === "TimeBox";
    const inputType = isDate ? "date" : isTime ? "time" : "datetime-local";

    // Get the value from the board
    const rawValue =
      editedRows[index]?.[column.column_id] ?? board[column.column_id];

    // Check if the value is a valid date using dayjs
    let formattedValue = "";
    if (rawValue) {
      let dateObj = dayjs(rawValue);

      // If the value is time-only (hh:mm) and lacks date information, add today's date
      if (isTime && !dateObj.isValid()) {
        // Check if the rawValue is in the time format without a date
        const timeOnlyRegex = /^\d{2}:\d{2}$/;
        if (timeOnlyRegex.test(rawValue)) {
          // Append today's date to the time
          dateObj = dayjs()
            .hour(rawValue.split(":")[0])
            .minute(rawValue.split(":")[1]);
        }
      }

      if (isDate) {
        formattedValue = dateObj.format("YYYY-MM-DD"); // Format as local date
      } else if (isTime) {
        formattedValue = dateObj.format("HH:mm"); // Format as local time
      } else {
        formattedValue = dateObj.format("YYYY-MM-DDTHH:mm"); // Format as local date and time
      }
    }

    return (
      <InputDateTime
        type={inputType}
        value={formattedValue}
        max="9999-12-31"
        onChange={(e) =>
          handleRowChange(index, column.column_id, e.target.value, board)
        }
        disabled={column.read_only}
      />
    );
  };

  const renderCheckBox = (column, board, index, read_only) => {
    const isChecked =
      editedRows[index]?.[column.column_id] ??
      (board[column.column_id] === "true" ? true : false);
    return (
      <Input
        type="checkbox"
        checked={isChecked}
        onChange={(e) =>
          handleRowChange(
            index,
            column.column_id,
            read_only ? e.target.checked : isChecked,
            board
          )
        }
        read_only={read_only ? column.read_only : true}
      />
    );
  };

  const renderDefaultInput = (column, board, index) => (
    <Input
      disabled={column.read_only}
      value={editedRows[index]?.[column.column_id] ?? board[column.column_id]}
      onChange={(e) =>
        handleRowChange(index, column.column_id, e.target.value, board)
      }
    />
  );

  const renderTextareaInput = (column, board, index) => (
    <textarea
      style={{ width: "100%", height: "100%" }}
      disabled={column.read_only}
      value={editedRows[index]?.[column.column_id] ?? board[column.column_id]}
      onChange={(e) =>
        handleRowChange(index, column.column_id, e.target.value, board)
      }
    />
  );

  const renderTextareaValue = (column, board, index) => {
    const rawValue = board[column.column_id];
    return (
      <div
        dangerouslySetInnerHTML={{ __html: rawValue }} // HTML 태그를 적용하여 렌더링
      />
    );
  };

  const renderControl = (column, board, index) => {
    // Check if the row is selected
    const isRowSelected = selectedRows.includes(index);

    // Render appropriate control based on the column type and selection state
    switch (column.control_type) {
      case "SelectBox":
        return isRowSelected
          ? renderSelectBox(column, board, index)
          : renderDisplayValue(column, board);
      case "ArraySelectBox":
        return isRowSelected
          ? ArraySelectBox(column, board, index)
          : renderDisplayValue(column, board);
      case "Button":
        return renderButton(column, board);
      case "ArrayButton":
        return renderArrayButton(column, board, index);
      case "MultiplyColumnPopup":
        return renderMultipleButtonColumns(column, board, index);
      case "DateTimeBox":
      case "DateBox":
      case "TimeBox":
        return isRowSelected
          ? renderDateTimeBox(column, board, index)
          : renderDisplayValue(column, board);
      case "CheckBox":
        return isRowSelected
          ? renderCheckBox(column, board, index, isRowSelected)
          : renderCheckBox(column, board, index, isRowSelected);
      case "LongText":
        return (
          <LongTextPopup
            text={board[column.column_id]}
            buttonName={column.column_title}
          ></LongTextPopup>
        );
      case "TextareaBox":
        return isRowSelected
          ? renderTextareaInput(column, board, index)
          : renderTextareaValue(column, board);
      case "MultiplyColumn":
        return renderMultipleColumns(board, index, column);
      default:
        return isRowSelected
          ? renderDefaultInput(column, board, index)
          : renderDisplayValue(column, board);
    }
  };
  // mask 값을 이용해서 관련 열들을 동적으로 가져오는 함수
  const getColumnsByMask = (mask) => {
    try {
      // mask가 배열 형태의 문자열로 저장되어 있으므로 이를 파싱하여 배열로 변환
      const parsedMask = mask
        .replace(/[\[\]']/g, "") // 대괄호와 작은 따옴표 제거
        .split(",") // 쉼표로 분리
        .map((colId) => colId.trim()); // 공백 제거
      return parsedMask;
    } catch (error) {
      console.error("Invalid mask format:", mask, error);
      return [];
    }
  };

  const renderMultipleButtonColumns = (board, index, column) => {
    // 예시로 여러 열을 정의, 각각의 열에 대해 데이터를 보여주기 위해 리스트를 사용
    const columnsToInclude = getColumnsByMask(column.mask);

    // 3개의 열씩 그룹화하여 배치
    const groupedColumns = [];
    for (let i = 0; i < columnsToInclude.length; i += 3) {
      groupedColumns.push(columnsToInclude.slice(i, i + 3));
    }

    // 대표 행(column)에서 column_align 값을 가져옴
    const columnAlign = column?.column_align || "vertical"; // 기본값은 'vertical'

    return (
      <div
        style={{
          display: columnAlign === "horizontal" ? "flex" : "block",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {groupedColumns.map((group, groupIndex) => (
          <div
            key={groupIndex}
            style={{
              display: columnAlign === "horizontal" ? "flex" : "block",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: columnAlign === "horizontal" ? "nowrap" : "normal", // 가로 모드일 때 줄바꿈 방지
              height: "100%", // 부모 요소 높이 가득 채우기
            }}
          >
            {group.map((colId, colIndex) => {
              // 열의 정보 가져오기
              const currentColumn = columns.find(
                (col) => col.column_id === colId
              );
              if (!currentColumn) return null;

              // 열의 값을 보여주기 위해 renderDisplayValue 사용
              return (
                <div
                  key={colId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // 중앙 정렬
                    marginBottom: columnAlign === "vertical" ? "5px" : "0",
                    marginRight:
                      columnAlign === "horizontal" &&
                      colIndex < group.length - 1
                        ? "10px"
                        : "0",
                    flex: columnAlign === "horizontal" ? 1 : "none",
                    textAlign: "center",
                    whiteSpace: "nowrap", // 가로 모드일 때 글이 줄바꿈되지 않도록 설정
                    height: "100%", // 부모 높이와 맞추기
                  }}
                >
                  {renderDisplayValue(currentColumn, board)}
                  {/* 가로 모드일 경우 마지막 요소가 아닌 경우 구분자 추가 */}
                  {columnAlign === "horizontal" &&
                    colIndex < group.length - 1 && (
                      <span style={{ height: "100%", margin: "0 5px" }}>/</span>
                    )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderMultipleColumns = (board, index, column) => {
    // 예시로 여러 열을 정의, 각각의 열에 대해 데이터를 보여주기 위해 리스트를 사용
    const columnsToInclude = getColumnsByMask(column.mask);

    // 3개의 열씩 그룹화하여 배치
    const groupedColumns = [];
    for (let i = 0; i < columnsToInclude.length; i += 3) {
      groupedColumns.push(columnsToInclude.slice(i, i + 3));
    }

    // 대표 행(column)에서 column_align 값을 가져옴
    const columnAlign = column?.column_align || "vertical"; // 기본값은 'vertical'

    return (
      <div
        style={{
          display: columnAlign === "horizontal" ? "flex" : "block",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {groupedColumns.map((group, groupIndex) => (
          <div
            key={groupIndex}
            style={{
              display: columnAlign === "horizontal" ? "flex" : "block",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: columnAlign === "horizontal" ? "nowrap" : "normal", // 가로 모드일 때 줄바꿈 방지
              height: "100%", // 부모 요소 높이 가득 채우기
            }}
          >
            {group.map((colId, colIndex) => {
              // 열의 정보 가져오기
              const currentColumn = columns.find(
                (col) => col.column_id === colId
              );
              if (!currentColumn) return null;

              // 열의 값을 보여주기 위해 renderDisplayValue 사용
              return (
                <div
                  key={colId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // 중앙 정렬
                    marginBottom: columnAlign === "vertical" ? "5px" : "0",
                    marginRight:
                      columnAlign === "horizontal" &&
                      colIndex < group.length - 1
                        ? "10px"
                        : "0",
                    flex: columnAlign === "horizontal" ? 1 : "none",
                    textAlign: "center",
                    whiteSpace: "nowrap", // 가로 모드일 때 글이 줄바꿈되지 않도록 설정
                    height: "100%", // 부모 높이와 맞추기
                  }}
                >
                  {" "}
                  {(() => {
                    switch (currentColumn.control_type) {
                      case "Button":
                        return renderButton(currentColumn, board);
                      case "ArrayButton":
                        return renderArrayButton(currentColumn, board, index);
                      default:
                        return renderDisplayValue(currentColumn, board);
                    }
                  })()}
                  {/* {renderDisplayValue(currentColumn, board)} */}
                  {/* 가로 모드일 경우 마지막 요소가 아닌 경우 구분자 추가 */}
                  {columnAlign === "horizontal" &&
                    colIndex < group.length - 1 && (
                      <span style={{ height: "100%", margin: "0 5px" }}>/</span>
                    )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  const renderDisplayValue = (column, board) => {
    const displayValue = (() => {
      if (["S", "M", "C"].includes(column.bind_type)) {
        if (column.control_type === "ArraySelectBox") {
          const rawValue = board[column.column_id];

          // 문자열을 쉼표로 구분하여 배열로 변환
          const valuesArray = rawValue?.split(",").map((value) => value.trim());

          // 배열의 각 값을 codeDisplayMap을 사용해 변환
          const mappedValues = valuesArray.map((value) => {
            const mappedValue = codeDisplayMap[column.bind_key]?.[value];
            return mappedValue ? mappedValue : value; // 매핑된 값이 있으면 사용, 없으면 원래 값 반환
          });

          // 변환된 값을 쉼표로 구분된 문자열로 반환
          const resultString = mappedValues.join(", ");

          // alert로 결과 보여주기 (예: "앞 중앙, 앞 우측")

          // 변환된 결과 반환
          return resultString;
        }

        return (
          codeDisplayMap[column.bind_key]?.[board[column.column_id]] ||
          board[column.column_id]
        );
      }

      // 기본 처리
      return board[column.column_id];
    })();

    if (column.mask === "PriceNum") {
      return formatNumberWithCommas(displayValue);
    } else if (column.mask === "DateTime") {
      if (!displayValue) {
        return "";
      }

      // 이미 포맷된 값이 아닌지 확인 (YYYY-MM-DD HH:mm 형식)
      if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(displayValue)) {
        return displayValue; // 이미 포맷된 값은 그대로 반환
      }

      const date = dayjs(displayValue);
      if (date.isValid()) {
        return date.format("YYYY-MM-DD HH:mm"); // 처음 처리된 값
      }

      return displayValue;
    } else if (column.mask === "Date") {
      if (!displayValue) {
        return "";
      }

      // 이미 포맷된 값이 아닌지 확인 (YYYY-MM-DD 형식)
      if (/^\d{4}-\d{2}-\d{2}$/.test(displayValue)) {
        return displayValue; // 이미 포맷된 값은 그대로 반환
      }

      const date = dayjs(displayValue);
      if (date.isValid()) {
        return date.format("YYYY-MM-DD"); // 처음 처리된 값
      }

      return displayValue;
    } else if (column.mask === "Time") {
      if (!displayValue) {
        return "";
      }

      // 이미 포맷된 값이 아닌지 확인 (HH:mm 형식)
      if (/^\d{2}:\d{2}$/.test(displayValue)) {
        return displayValue; // 이미 포맷된 값은 그대로 반환
      }

      const date = dayjs(displayValue);
      if (date.isValid()) {
        return date.format("HH:mm"); // 처음 처리된 값
      }

      return displayValue;
    } else if (column.mask === "LongText") {
      return (
        <LongTextPopup text={displayValue} buttonName={column.column_title} />
      );
    } else if (column.mask === "Files") {
      return board[column.column_id] ? (
        <FileIcon>
          <FontAwesomeIcon icon={faPaperclip} />
        </FileIcon>
      ) : (
        <></>
      );
    } else if (
      typeof column.mask === "string" &&
      column.mask.startsWith("hiddenText-")
    ) {
      const maxLength = parseInt(column.mask.split("-")[1], 10);
      if (!isNaN(maxLength) && typeof displayValue === "string") {
        return displayValue.length > maxLength
          ? `${displayValue.slice(0, maxLength)}...`
          : displayValue;
      }
      return displayValue;
    }
    return displayValue;
  };

  const formatNumberWithCommas = (number) => {
    if (!number || isNaN(number)) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const sortedColumns = [...columns].sort((a, b) => {
    const idxA =
      a.column_idx !== undefined ? a.column_idx : Number.MAX_SAFE_INTEGER;
    const idxB =
      b.column_idx !== undefined ? b.column_idx : Number.MAX_SAFE_INTEGER;
    return idxA + idxB; // 작은 값부터 큰 값 순으로 정렬
  });

  return (
    <StyledTable>
      {showPasswordChangePopup && (
        <PasswordChangePopup
          onClose={handleClosePasswordChangePopup}
          onSubmit={handlePasswordChangeSubmit}
        />
      )}
      <StyledThead>
        <tr>
          <StyledTh style={{ width: "10px" }}>
            <Input
              type="checkbox"
              checked={isAllSelected}
              onChange={handleHeaderCheckboxChange}
            />
          </StyledTh>
          {sortedColumns.map(
            (column, index) =>
              column.cell_visible && (
                <StyledTh
                  key={index}
                  style={{ width: column.column_width || "auto" }}
                >
                  {column.mapping_name || column.column_title}
                </StyledTh>
              )
          )}
        </tr>
      </StyledThead>
      <StyledTbody>
        {boards.map((board, index) => (
          <StyledTr
            key={index}
            onDoubleClick={() => {
              setIsOpenViewer(!isOpenViewer);
              setOpenPost(board);
            }}
          >
            <StyledTd style={{ width: "10px" }}>
              <Input
                type="checkbox"
                checked={selectedRows.includes(index)}
                onChange={() => toggleRowSelection(index, board)}
              />
            </StyledTd>
            {sortedColumns.map(
              (column, colIndex) =>
                column.cell_visible && (
                  <StyledTd
                    key={colIndex}
                    style={{
                      width: column.column_width || "auto",
                      textAlign: column.column_align || "left",
                    }}
                  >
                    {renderControl(column, board, index)}
                  </StyledTd>
                )
            )}
          </StyledTr>
        ))}
      </StyledTbody>
      {saveMessage && (
        <div style={{ textAlign: "center", marginTop: "10px", color: "green" }}>
          {saveMessage}
        </div>
      )}
    </StyledTable>
  );
}
