import React, { useState, useEffect } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import SubNavigator from '../../components/SubNavigator';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../AppContext';
const staticLinks = [
  // { path: '/Franchisee/franchisee-list', label: '가맹점관리' },
];

export default function Franchisee() {
  const { logout } = useApp();
  const location = useLocation();
  const navigate = useNavigate();
  const [links, setLinks] = useState(staticLinks);

  const checkSession = async() => {
    const user = sessionStorage.getItem('user');
    if (!user) {
      // 세션이 없을 경우 로그인 페이지로 리다이렉트
      logout();
      navigate('/login');
      //console.log('로그아웃 상태');
      return false;
    }
    //console.log('로그인 상태');
    return true;
  };
  
  useEffect(() => {
    // API 호출하여 테이블 이름 가져오기
    const fetchBoardTables = async () => {
      try {
        const response = await fetch('/request/getBoardTables?category=Franchisee',{credentials:'include'}); // 실제 API 엔드포인트로 변경
        const data = await response.json();

        // 가져온 테이블 이름을 링크로 변환
        const dynamicLinks = data.map(table => ({
          path: `/Franchisee/Board/${table.boardId}`,
          label: table.boardTitle // ants_ 및 _board 제거하여 보기 좋게 만듦
        }));
          //console.log('조회완료');
          if(!await checkSession()) {
            return ;
          };
        // 정적 링크와 동적 링크 결합
        setLinks([...staticLinks, ...dynamicLinks]);
      } catch (error) {
        console.error('Error fetching board tables:', error);
      }
    };

    fetchBoardTables();
  }, []);
    
  useEffect(()=>{
    if (links.length > 0 ) {
      if(location?.state?.fromNavigator===true){
        navigate(links[0].path);
      }
    }
  },[location,links])

  return (
    <div className="Franchisee" style={{ display: 'flex', width: '100%', height: '100%' }}>
      <nav style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'gray' }}>
        <SubNavigator links={links} />
      </nav>
      <main style={{ display: 'flex', width: '100%', background: '#fff' }}>
        <Outlet />
      </main>
    </div>
  );
}








