import React, { useState } from "react";
import styled from "styled-components";

const ReqRefundView = ({ setIsShowDynamicPopup, isShowDynamicPopup, rows, popupParam }) => {
  const [refundData, setRefundData] = useState(
    rows.map((row) => ({
      ...row,  // row의 모든 정보를 복사하여 유지
      paymentMethod: "무통장입금",
      paymentAmount: "",
    }))
  );

  const handleInputChange = (index, field, value) => {
    const updatedRefundData = [...refundData];
    updatedRefundData[index][field] = value;
    setRefundData(updatedRefundData);
  };

  const handleConfirmClick = () => {
    for (let i = 0; i < refundData.length; i++) {
      if (!refundData[i].paymentAmount) {
        alert("모든 행에 대해 결제수단과 결제금액을 입력해주세요.");
        return;
      }
    }

    if (popupParam && typeof popupParam === 'function') {
      popupParam(refundData);
    } else {
      console.error('popupParam is not defined or not a function.');
    }

    setIsShowDynamicPopup(false); 
  };

  return (
    <PopupContainer>
      <PopupContent>
        <Title>환불 요청</Title>
        <Form>
          {rows.map((row, index) => (
            <RowContainer key={index}>
              <InfoHeader>{index + 1}. {row.movie_title} ({row.theater})</InfoHeader>
              <InfoLabel>
                사용자: {row.user_name} | 연락처: {row.user_id} | 결제금액: {row.itemamount}
              </InfoLabel>
              <LabelContainer>
                <label>
                  <Input
                    type="text"
                    value={'무통장입금'}
                    disabled
                    onChange={(e) => handleInputChange(index, "paymentMethod", e.target.value)}
                    placeholder="결제수단을 입력하세요"
                  />
                </label>
                <label>
                  <Input
                    type="number"
                    value={refundData[index].paymentAmount}
                    onChange={(e) => handleInputChange(index, "paymentAmount", e.target.value)}
                    placeholder="환불금액을 입력하세요"
                  />
                </label>
              </LabelContainer>
            </RowContainer>
          ))}
        </Form>
        <ButtonContainer>
          <ActionButton onClick={handleConfirmClick}>확인</ActionButton>
          <CancelButton onClick={() => setIsShowDynamicPopup(false)}>닫기</CancelButton>
        </ButtonContainer>
      </PopupContent>
    </PopupContainer>
  );
};

// Styled-components for a more modern and clean design
const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: #f9f9f9;
  padding: 10px;
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
  font-family: 'Helvetica Neue', sans-serif;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 1.8rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RowContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  text-align:left;
`;

const InfoHeader = styled.h4`
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const InfoLabel = styled.p`
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

const Input = styled.input`
  width: calc(100% - 12px);
  max-width: 300px;
  padding: 12px;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
`;

const ActionButton = styled.button`
  padding: 12px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled.button`
  padding: 12px 30px;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #999;
  }
`;

export default ReqRefundView;
