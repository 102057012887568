import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { delSessionFunc } from "../api/func/userFunc";
export const AppContext = createContext({
  isLoggedIn: false,
  user: null,
  logout: () => {},
  setUser: () => {},
});

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  let navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const login = async (userData) => {
    // console.log(`## login start ##`);
    sessionStorage.setItem("user", JSON.stringify(userData));
    setIsLoggedIn(true);
    setUser(userData);
    navigate("/"); // 로그인 후 페이지로 리디렉션
    // console.log(`## login end ##`);
  };

  const logout = async () => {
    // console.log(`## logout start ##`);
    const storedUser = await JSON.parse(sessionStorage.getItem("user"));
    console.log(storedUser);
    await delSessionFunc(storedUser.id);
    sessionStorage.removeItem("user");

    setIsLoggedIn(false);
    setUser(null);
    // console.log(`## logout end ##`);
    navigate("/login"); // 로그아웃 후 로그인 페이지로 리디렉션
  };

  return (
    <AppContext.Provider
      value={{ isLoggedIn, user, login, logout, setIsLoggedIn, setUser }}
    >
      {children}
    </AppContext.Provider>
  );
};
