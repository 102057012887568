import { saveAs } from 'file-saver';



export const member_delete = async(row) =>{
  return row; // 필요한 경우 결과를 반환
}

export const pw_change = async (row) => {
  try {
    //console.log(JSON.stringify(row)); // 사용자 정보 확인을 위한 알림

    const options = {
      credentials:'include',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: row.id, // 사용자의 ID
        newPassword: row.new_password, // 새로운 비밀번호
      }),
    };

    const response = await fetch('/request/pw_change', options);
    const result = await response.json();

    if (response.ok) {
      alert('비밀번호가 성공적으로 변경되었습니다.');
    } else {
      alert(`오류 발생: ${result.message}`);
    }

    return result; // 필요한 경우 결과를 반환
  } catch (error) {
    alert('비밀번호 변경 중 오류가 발생했습니다: ' + error.message);
  }
};

export const order_agent = async (rows,refrash) => {
  try {
    // rows가 비어있을 때 경고 메시지 표시
    if (!rows || rows.length === 0) {
      alert('항목을 선택하세요');
      return; // 함수 종료
    }

    // 모든 행에 대해 비동기 요청을 병렬로 처리
    const filteredRows = rows.filter(row => row.agent_status === 'false');

    // '대행진행중'인 행들 (console.log로 표시할 목록)
    const excludedRows = rows.filter(row => row.agent_status !== 'false');
    const promises = filteredRows.map(async (row) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // 각 행(row)의 데이터를 요청에 포함
        body: JSON.stringify(row),
      };
      if(row.payment_status ==='pending'){
        if(!window.confirm('쿠폰번호:'+row.coupon_code + " 입금전 주문입니다 진행하시겠습니까?"))
          return;
      }

      const response = await fetch('https://cinechain.co.kr/Acution/agent_request', options);
      const result = await response.json();
      refrash();
      return result; // 필요한 경우 결과를 반환
    });

    // 모든 요청이 완료될 때까지 대기
    const results = await Promise.all(promises);

    // 모든 결과 처리
    console.log('All responses:', results);
    alert(
      `대행 요청 성공 목록 (${filteredRows.length}건)\n[${filteredRows.map(row => `${row.coupon_code}`).join(', ')}]\n\n이미 진행중인 요청 목록 (${excludedRows.length}건)\n[${excludedRows.map(row => ` ${row.coupon_code}`).join(', ')}]`
    );
  } catch (error) {
    alert(`Error: ${error.message}`);
  }
};

export const order_refund = async (rows,refrash) => {
  try {
    // rows가 비어있을 때 경고 메시지 표시
    if (!rows || rows.length === 0) {
      alert('항목을 선택하세요');
      return; // 함수 종료
    }

    // rows 배열을 순회하며 각 요청을 처리
    for (const row of rows) {  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ row }),  // 개별 row를 본문에 추가
      };
      console.log(JSON.stringify(row));
      if(row.payment_status ==='pending'){
        alert('입금완료건만 환불이 가능합니다.');
          return;
      }
      const response = await fetch('/request/order_refund', options);

      if (!response.ok) {  // 응답 상태가 OK가 아닌 경우 에러 처리
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(`Result for row ${JSON.stringify(row)}: `, result);  // 각 요청의 결과를 콘솔에 출력
    }
    
    alert('환불 요청이 완료되었습니다.');
    refrash();

  } catch (error) {
    alert(`Error: ${error.message}`);  // 에러 메시지 출력
  }
};

export const order_cancel = async (rows,refrash) => {
  try {
    // rows가 비어있을 때 경고 메시지 표시
    if (!rows || rows.length === 0) {
      alert('항목을 선택하세요');
      return; // 함수 종료
    }

    // 환불 불가 처리된 건들을 저장할 배열
    const cannotCancelItems = [];

    // 모든 행에 대해 비동기 요청을 병렬로 처리
    const promises = rows.map(async (row) => {
      if (row.payment_status === 'success') {
        cannotCancelItems.push(row); // 환불 불가 처리된 건을 배열에 추가
        return; // 환불 요청을 하지 않고 함수 종료
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // 각 행(row)의 데이터를 요청 본문에 포함
        body: JSON.stringify({ row }),
      };

      // 각 요청에 대해 fetch 실행
      const response = await fetch('/request/order_cancel', options);

      if (!response.ok) { // 응답 상태가 OK가 아닌 경우 에러 처리
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // 결과를 JSON으로 파싱
      const result = await response.json();
      refrash();
      return result; // 필요 시 결과 반환
    });

    // 모든 요청이 완료될 때까지 대기
    const results = await Promise.all(promises);

    // 환불 불가 처리된 건들에 대한 알림 출력
    if (cannotCancelItems.length > 0) {
      const cannotCancelMessage = cannotCancelItems.map(item => `주문 번호: ${item.order_number}`).join('\n');
      alert(`환불 요청이 불가능한 항목입니다:\n${cannotCancelMessage}`);
    }
    // 모든 결과 처리
    //console.log('All cancel responses:', results);
    alert('취소 요청이 완료되었습니다.');
    refrash();

  } catch (error) {
    alert(`Error: ${error.message}`); // 에러 메시지 출력
  }
};

export const order_accident = async (rows,refrash) => {
  try {
    // rows가 비어있을 때 경고 메시지 표시
    if (!rows || rows.length === 0) {
      alert('항목을 선택하세요');
      return; // 함수 종료
    }

    // 모든 행에 대해 비동기 요청을 병렬로 처리
    const promises = rows.map(async (row) => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // 각 행(row)의 데이터를 요청 본문에 포함
        body: JSON.stringify({ row }),
      };

      // 각 요청에 대해 fetch 실행
      const response = await fetch('/request/accident_reg', options);

      if (!response.ok) { // 응답 상태가 OK가 아닌 경우 에러 처리
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // 결과를 JSON으로 파싱
      const result = await response.json();

      return result; // 필요 시 결과 반환
    });

    // 모든 요청이 완료될 때까지 대기
    const results = await Promise.all(promises);

    // 모든 결과 처리
    //console.log('All accident responses:', results);
    alert('사고 등록이 완료되었습니다.');
    refrash();
  } catch (error) {
    alert(`Error: ${error.message}`); // 에러 메시지 출력
  }
};

export const couponSetExport = (rows) => {
  try {
    const headers = ['식별키', '쿠폰코드', '유효기간종료일'];

    // CSV 데이터 생성: 'fran_type'과 'issue_cnt'를 제외한 데이터로 구성
    const csvData = [
      headers, // 첫 번째 행에 헤더 추가
      ...rows.map(row => {
        const { fran_type, issue_cnt, ...filteredRow } = row; // fran_type과 issue_cnt 제외
        return Object.values(filteredRow); // 나머지 필드의 값만 반환
      })
    ];

    // CSV 콘텐츠를 생성하여 파일로 저장
    const BOM = '\uFEFF';
    const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    if (rows.length === 0) {
      alert('쿠폰 목록이 0건입니다.');
    } else {
      // fran_type과 issue_cnt는 파일명에만 사용
      saveAs(blob, '[' + rows[0].fran_type + '-' + String(rows[0].issue_cnt).padStart(4, '0') + ']_' + 'export.csv');
      alert('엑셀 내보내기 버튼 클릭됨');
    }
  } catch (error) {
    alert(error.message);
  }
};

export const userAdd = async (setIsShowPopup)=>{
  setIsShowPopup(true);
}

export const refundExportItem = async (rows,refrash) => {
  try {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ rows }), // rows 데이터를 body에 포함
    };
    const response = await fetch('/request/refund_down_rows', options);
    const result = await response.json();


    // 은행 목록 데이터
    const banks = [
      { bank_code: '004', bank_name: '국민' },
      { bank_code: '003', bank_name: '기업' },
      { bank_code: '011', bank_name: '농협' },
      { bank_code: '071', bank_name: '우체국' },
      { bank_code: '027', bank_name: 'SC(스탠다드차타드)' },
      { bank_code: '081', bank_name: 'KEB하나(구 외환 포함)' },
      { bank_code: '088', bank_name: '신한(구 조흥 포함)' },
      { bank_code: '045', bank_name: '새마을금고' },
      { bank_code: '050', bank_name: '상호저축은행' },
      { bank_code: '023', bank_name: '한국씨티은행' },
      { bank_code: '031', bank_name: '대구' },
      { bank_code: '039', bank_name: '경남' },
      { bank_code: '034', bank_name: '광주' },
      { bank_code: '032', bank_name: '부산' },
      { bank_code: '007', bank_name: '수협' },
      { bank_code: '002', bank_name: '산업' },
      { bank_code: '048', bank_name: '신협' },
      { bank_code: '051', bank_name: '홍콩상하이은행(HSBC)' },
      { bank_code: '055', bank_name: 'BOA(Bank of America)' },
      { bank_code: '057', bank_name: '제이피모간체이스' },
    ];

    // 은행 이름으로 은행 코드를 찾는 함수
    const getBankCodeByName = (name) => {
      const bank = banks.find(bank => bank.bank_name === name);
      return bank ? bank.bank_code : '';
    };

    // 유의사항 데이터
    const notes = [
      ['유의사항 (본 유의사항은 대량이체파일 등록 전에 모두 삭제해 주십시오.)', ''],
      ['1) 출금(필수)', '셀서식을 텍스트로 지정한 후 은행코드 3자리 숫자 또는 은행(기관)명 입력'],
      ['2) 입금(필수)', '셀서식을 텍스트로 지정한 후 입금계좌번호 입력 (- 생략 가능)'],
      ['3) 명세(필수)', '예금주명(띄어쓰기), 특수문자 입력 불가'],
      ['4) 메모(선택)', '입금계좌적요 (미입력 시 출금계좌의 예금주가 입금계좌 적요에 자동적용, 최대 10자리 입력 가능)'],
      ['5) CMS입금(선택)', 'CMS입금인 경우 CMS코드 3 입력 (당행만 가능)'],
      ['6) 메모(선택)', '메모기능으로 최대 10자리 입력 가능'],
    ];

    // CSV 데이터 생성
    const csvData = [];
    const maxColumns = 20; // A ~ T 열까지 총 20열

    // 환불 내역 추가 (A열부터)
    result.forEach(row => {
      const { bank_name, account_number, itemamount, user_name } = row;
      const dataRow = new Array(maxColumns).fill('');

      // 은행 코드 가져오기
      const bank_code = getBankCodeByName(bank_name) || bank_name;

      // 데이터 가공
      dataRow[0] = `"${bank_code}"`;       // A열: 은행 코드 또는 은행명 (텍스트 형식)
      dataRow[1] = `"${account_number}"`;  // B열: 계좌번호 (텍스트 형식)
      dataRow[2] = itemamount;             // C열: 이체금액
      dataRow[3] = user_name?.replace(/[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // D열: 예금주명 (특수문자 제거)
      dataRow[4] = '영화예매환불'.substring(0, 10);    // E열: 사유 (최대 10자리)

      csvData.push(dataRow);
    });

    // 빈 행 추가하여 환불 내역과 유의사항을 구분
    csvData.push(new Array(maxColumns).fill(''));
    csvData.push(new Array(maxColumns).fill(''));

    // 유의사항 추가 (L열부터)
    notes.forEach(noteRow => {
      const row = new Array(maxColumns).fill('');
      row[11] = noteRow[0]; // L열
      row[12] = noteRow[1]; // M열
      csvData.push(row);
    });

    // 빈 행 추가하여 유의사항과 은행 목록을 구분
    csvData.push(new Array(maxColumns).fill(''));
    csvData.push(new Array(maxColumns).fill(''));

    // 은행 목록 추가 (L열과 M열에)
    const bankHeaderRow = new Array(maxColumns).fill('');
    bankHeaderRow[11] = '은행 코드'; // L열
    bankHeaderRow[12] = '은행 이름'; // M열
    csvData.push(bankHeaderRow);

    banks.forEach(({ bank_code, bank_name }) => {
      const row = new Array(maxColumns).fill('');
      row[11] = bank_code; // L열
      row[12] = bank_name; // M열
      csvData.push(row);
    });

    // CSV 콘텐츠를 생성하여 파일로 저장
    const BOM = '\uFEFF';
    const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    if (result.length === 0) {
      alert('환불 목록이 0건입니다.');
    } else {
      saveAs(blob, '환불내역_' + new Date().toLocaleDateString() + '.csv');
      //console.log('환불내역 버튼 클릭됨');
    }
  } catch (error) {
    alert(error.message);
  }
};

export const refundExport = async () => {
  try {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch('/request/refund_down', options);
    const result = await response.json();

    // 은행 목록 데이터
    const banks = [
      { bank_code: '004', bank_name: '국민' },
      { bank_code: '003', bank_name: '기업' },
      { bank_code: '011', bank_name: '농협' },
      { bank_code: '071', bank_name: '우체국' },
      { bank_code: '027', bank_name: 'SC(스탠다드차타드)' },
      { bank_code: '081', bank_name: 'KEB하나(구 외환 포함)' },
      { bank_code: '088', bank_name: '신한(구 조흥 포함)' },
      { bank_code: '045', bank_name: '새마을금고' },
      { bank_code: '050', bank_name: '상호저축은행' },
      { bank_code: '023', bank_name: '한국씨티은행' },
      { bank_code: '031', bank_name: '대구' },
      { bank_code: '039', bank_name: '경남' },
      { bank_code: '034', bank_name: '광주' },
      { bank_code: '032', bank_name: '부산' },
      { bank_code: '007', bank_name: '수협' },
      { bank_code: '002', bank_name: '산업' },
      { bank_code: '048', bank_name: '신협' },
      { bank_code: '051', bank_name: '홍콩상하이은행(HSBC)' },
      { bank_code: '055', bank_name: 'BOA(Bank of America)' },
      { bank_code: '057', bank_name: '제이피모간체이스' },
    ];

    // 은행 이름으로 은행 코드를 찾는 함수
    const getBankCodeByName = (name) => {
      const bank = banks.find(bank => bank.bank_name === name);
      return bank ? bank.bank_code : '';
    };

    // 유의사항 데이터
    const notes = [
      ['유의사항 (본 유의사항은 대량이체파일 등록 전에 모두 삭제해 주십시오.)', ''],
      ['1) 출금(필수)', '셀서식을 텍스트로 지정한 후 은행코드 3자리 숫자 또는 은행(기관)명 입력'],
      ['2) 입금(필수)', '셀서식을 텍스트로 지정한 후 입금계좌번호 입력 (- 생략 가능)'],
      ['3) 명세(필수)', '예금주명(띄어쓰기), 특수문자 입력 불가'],
      ['4) 메모(선택)', '입금계좌적요 (미입력 시 출금계좌의 예금주가 입금계좌 적요에 자동적용, 최대 10자리 입력 가능)'],
      ['5) CMS입금(선택)', 'CMS입금인 경우 CMS코드 3 입력 (당행만 가능)'],
      ['6) 메모(선택)', '메모기능으로 최대 10자리 입력 가능'],
    ];

    // CSV 데이터 생성
    const csvData = [];
    const maxColumns = 20; // A ~ T 열까지 총 20열

    // 환불 내역 추가 (A열부터)
    result.forEach(row => {
      const { bank_name, account_number, itemamount, user_name } = row;
      const dataRow = new Array(maxColumns).fill('');

      // 은행 코드 가져오기
      const bank_code = getBankCodeByName(bank_name) || bank_name;

      // 데이터 가공
      dataRow[0] = `"${bank_code}"`;       // A열: 은행 코드 또는 은행명 (텍스트 형식)
      dataRow[1] = `"${account_number}"`;  // B열: 계좌번호 (텍스트 형식)
      dataRow[2] = itemamount;             // C열: 이체금액
      dataRow[3] = user_name.replace(/[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]/g, ''); // D열: 예금주명 (특수문자 제거)
      dataRow[4] = '영화예매환불'.substring(0, 10);    // E열: 사유 (최대 10자리)

      csvData.push(dataRow);
    });

    // 빈 행 추가하여 환불 내역과 유의사항을 구분
    csvData.push(new Array(maxColumns).fill(''));
    csvData.push(new Array(maxColumns).fill(''));

    // 유의사항 추가 (L열부터)
    notes.forEach(noteRow => {
      const row = new Array(maxColumns).fill('');
      row[11] = noteRow[0]; // L열
      row[12] = noteRow[1]; // M열
      csvData.push(row);
    });

    // 빈 행 추가하여 유의사항과 은행 목록을 구분
    csvData.push(new Array(maxColumns).fill(''));
    csvData.push(new Array(maxColumns).fill(''));

    // 은행 목록 추가 (L열과 M열에)
    const bankHeaderRow = new Array(maxColumns).fill('');
    bankHeaderRow[11] = '은행 코드'; // L열
    bankHeaderRow[12] = '은행 이름'; // M열
    csvData.push(bankHeaderRow);

    banks.forEach(({ bank_code, bank_name }) => {
      const row = new Array(maxColumns).fill('');
      row[11] = bank_code; // L열
      row[12] = bank_name; // M열
      csvData.push(row);
    });

    // CSV 콘텐츠를 생성하여 파일로 저장
    const BOM = '\uFEFF';
    const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    if (result.length === 0) {
      alert('환불 목록이 0건입니다.');
    } else {
      saveAs(blob, '환불내역_' + new Date().toLocaleDateString() + '.csv');
      //console.log('환불내역 버튼 클릭됨');
    }
  } catch (error) {
    alert(error.message);
  }
};

// export const refundExport2 = async () => {
  
//   try {
//     const options = {
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     };
//     const response = await fetch('/request/refund_down', options);
//     const result = await response.json();
    
//     const headers = ['은행명'	,'계좌번호'	,'이체금액'	,'예금주성명'	,'사유'];
//     // 1.입금내역과 매칭된 데이터를 보관한 테이블이 필요함 입금 내역 ants_deposit_list

//     // 2.입금내역에 맞는 주문번호 데이터를 매칭해서 가저옴

//     // 3.매칭내역 테이블에서 조회된 사용자 정보에 등록된 환불계좌 및 은행명을 엑셀에 다운로드함

//     // SELECT  id, orderindex, ordercartegory, user_id, user_name, itemamount, coupon_code FROM .ants_order_list

//     // 여기서 .ants_personal_info_mgmt 테이블을 user_id 와 user_name 으로 조회한 다음 

//     //은행명은 bank_name ,account_number,account_holder_name ,ants_order_list.user_name,ants_order_listitemamount 을 가저와줘

//     // CSV 데이터 생성: 'fran_type'과 'issue_cnt'를 제외한 데이터로 구성
//     const csvData = [
//       headers, // 첫 번째 행에 헤더 추가
//       ...result.map(row => {
//         const { ...filteredRow } = row; // fran_type과 issue_cnt 제외
//         return Object.values(filteredRow); // 나머지 필드의 값만 반환
//       })
//     ];

//     // CSV 콘텐츠를 생성하여 파일로 저장
//     const BOM = '\uFEFF';
//     const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

//     if (result.length === 0) {
//       alert('환불 목록이 0건입니다.');
//     } else {
//       // fran_type과 issue_cnt는 파일명에만 사용
//       //console.log(JSON.stringify(result));
//       saveAs(blob, '[' + result[0].fran_type + '-' + String(result[0].issue_cnt).padStart(4, '0') + ']_' + 'export.csv');
//       console.log('환불내역 버튼 클릭됨');
//     }
//   } catch (error) {
//     alert(error.message);
//   }
// };



export const statisticExport = async () => {
  
  try {
    const options = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch('/request/statistic_down', options);
    const result = await response.json();
    const headers = ['가맹점명'	,'쿠폰종류'	,'유효기간'	,'발급쿠폰수'	,'인증쿠폰수'	,'인증율'	,'예매쿠폰수',	'예매율'];

    // CSV 데이터 생성: 'fran_type'과 'issue_cnt'를 제외한 데이터로 구성
    const csvData = [
      headers, // 첫 번째 행에 헤더 추가
      ...result.map(row => {
        const { ...filteredRow } = row; // fran_type과 issue_cnt 제외
        return Object.values(filteredRow); // 나머지 필드의 값만 반환
      })
    ];

    // CSV 콘텐츠를 생성하여 파일로 저장
    const BOM = '\uFEFF';
    const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    if (result.length === 0) {
      alert('쿠폰 목록이 0건입니다.');
    } else {
      // fran_type과 issue_cnt는 파일명에만 사용
      //console.log(JSON.stringify(result));
      saveAs(blob, '[' + result[0].fran_type + '-' + String(result[0].issue_cnt).padStart(4, '0') + ']_' + 'export.csv');
      alert('엑셀 내보내기 버튼 클릭됨');
    }
  } catch (error) {
    alert(error.message);
  }
};


//전체발행 
export const couponSetExportAll = (rows) => {
    // CSV 데이터 생성: 첫 번째 행은 헤더, 그 다음은 rows 데이터
    // console.log('[rows값]:'+JSON.stringify(rows));
    const headers = ['식별키','발행일','쿠폰코드','가맹점코드','쿠폰종류','쿠폰수','유효기간시작일','유효기간종료일','유효기간일','시간그룹','예매제한','주문최대인원','주문최소인원','발행횟차'];

    // CSV 데이터 생성: 첫 번째 행은 헤더, 그 다음은 rows 데이터
    const csvData = [
        headers, // 첫 번째 행에 헤더 추가
        ...rows.map(row => Object.values(row)) // 이후 각 행의 데이터 추가
    ];
    // CSV 콘텐츠를 생성하여 파일로 저장
    const BOM = '\uFEFF';
    const csvContent = BOM + csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob,'['+ rows[0].fran_type + '-' + rows[0].issue_cnt.toString().padStart(3, '0')+']_' + 'export.csv');
  
    //console.log('엑셀 내보내기 버튼 클릭됨');
  };