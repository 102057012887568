import React, { useEffect, useState } from "react";
import styled from "styled-components";

const UserInfoView = ({ setIsShowDynamicPopup, isShowDynamicPopup, rows, popupParam }) => {
  const [Record, setRecord] = useState(popupParam);

  useEffect(() => {
    setRecord(popupParam);
  }, [popupParam]);
  useEffect(() => {
    if (Record.name && Record.phone_number) {
      fetch(`/request/getUserBankInfo?name=${Record.name}&phone_number=${Record.phone_number}`)
        .then(response => response.json())
        .then(data => {
          if (data.bank_name) {
            setRecord({
              ...Record,
              bank_name: data.bank_name,
              account_number: data.account_number,
              account_holder_name: data.account_holder_name
            });
          } else {
            console.error("User bank info not found");
          }
        })
        .catch(error => console.error("Error fetching user bank info:", error));
    }
  }, [Record.name, Record.phone_number]);

  const [userInfo, setUserInfo] = useState({
    name: "",
    phoneNumber: "",
    grade: "",
    refundAccount: "",
    accountHolder: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setIsShowDynamicPopup(false);
  };


  //antsnest.ants_personal_info_mgmt 여기 넘어온 name과 phone_number 두개로 
  //은행정보환불계좌 가저와야해

  return (
    <PopupContainer>
      <PopupContent>
        <Title>사용자 정보</Title>
        <Form>
          <FormGroup>
            <label>이름:</label>
            <input
              type="text"
              name="name"
              value={Record.name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label>휴대폰 번호:</label>
            <input
              type="text"
              name="phoneNumber"
              value={Record.phone_number}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label>등급:</label>
            <input
              type="text"
              name="grade"
              value={Record.Rank}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label>은행명:</label>
            <input
              type="text"
              name="refundAccount"
              value={Record.bank_name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label>환불계좌:</label>
            <input
              type="text"
              name="refundAccount"
              value={Record.account_number}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label>예금주:</label>
            <input
              type="text"
              name="accountHolder"
              value={Record.account_holder_name}
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
        <ButtonContainer>
          {/* <Button primary onClick={handleSubmit}>저장</Button> */}
          <Button onClick={() => setIsShowDynamicPopup(false)}>닫기</Button>
        </ButtonContainer>
      </PopupContent>
    </PopupContainer>
  );
};

// Styled-components for styling the popup
const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 12px;
  width: 420px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }

  input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #333;

    &:focus {
      outline: none;
      border-color: #007bff;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 25px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  background-color: ${(props) => (props.primary ? "#007bff" : "#ccc")};
  color: white;

  &:hover {
    background-color: ${(props) => (props.primary ? "#0056b3" : "#aaa")};
  }
`;

export default UserInfoView;