import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

export default function RefundDown({ setIsShowDynamicPopup, onRefresh }) {
  useEffect(() => {
    //refrash();
  }, [onRefresh]);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // 날짜 설정 함수
  const setOneMonthPeriod = () => {
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1);
    setFromDate(oneMonthAgo.toISOString().slice(0, 16));
    setToDate(now.toISOString().slice(0, 16));
  };

  const setThreeMonthPeriod = () => {
    const now = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(now.getMonth() - 3);
    setFromDate(threeMonthsAgo.toISOString().slice(0, 16));
    setToDate(now.toISOString().slice(0, 16));
  };

  // 환불 내역 다운로드 함수
  const refundExport = async () => {
    try {
      const options = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // 인증 정보 포함
        body: JSON.stringify({ fromDate, toDate }),
      };
      const response = await fetch('/request/refund_down', options);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('API 호출 실패:', errorText);
        throw new Error(`API 호출 실패: ${errorText}`);
      }

      const result = await response.json();
      //console.log('API 응답 데이터:', result);

      if (result.length === 0) {
        alert('환불 목록이 0건입니다.');
        return;
      }

      // 워크북 및 워크시트 생성
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('환불내역');

      // 모든 열 정의 (A열부터 T열까지)
      worksheet.columns = [
        // { header: '은행명', key: 'bank_name', width: 15 },    // A열
        // { header: '계좌번호', key: 'account_number', width: 20 }, // B열
        // { header: '이체금액', key: 'refund_amount', width: 15 },  // C열
        // { header: '예금주성명', key: 'user_name', width: 15 },   // D열
        // { header: '사유', key: 'reason', width: 20 },          // E열
        { key: 'colF', width: 10 },  // F열
        { key: 'colG', width: 10 },  // G열
        { key: 'colH', width: 10 },  // H열
        { key: 'colI', width: 10 },  // I열
        { key: 'colJ', width: 10 },  // J열
        { key: 'colK', width: 10 },  // K열
        { key: 'colL', width: 30 },  // L열
        { key: 'colM', width: 50 },  // M열
        { key: 'colN', width: 10 },  // N열
        { key: 'colO', width: 10 },  // O열
        { key: 'colP', width: 10 },  // P열
        { key: 'colQ', width: 10 },  // Q열
        { key: 'colR', width: 10 },  // R열
        { key: 'colS', width: 10 },  // S열
        { key: 'colT', width: 10 },  // T열
      ];

      // 환불 내역 헤더 추가 (A1~E1)
      // const headerRow = worksheet.getRow(1);
      // ['A', 'B', 'C', 'D', 'E'].forEach((col, idx) => {
      //   const cell = headerRow.getCell(col);
      //   cell.value = worksheet.columns[idx].header;
      //   cell.font = { bold: true };
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'FFFFE0' }, // 옅은 노란색 배경
      //   };
      //   cell.alignment = { vertical: 'middle', horizontal: 'center' };
      //   cell.border = {
      //     top: { style: 'thin' },
      //     left: { style: 'thin' },
      //     bottom: { style: 'thin' },
      //     right: { style: 'thin' },
      //   };
      // });
      // headerRow.commit();

      // 환불 내역 데이터 추가 (A2부터)
      result.forEach((rowData, index) => {
        //console.log('데이터 행:', rowData);

        const rowIndex = index + 1; // 데이터는 2행부터 시작
        const row = worksheet.getRow(rowIndex);
        row.getCell('A').value = rowData.bank_name;
        row.getCell('B').value = rowData.account_number;
        row.getCell('C').value = rowData.refund_amount;
        row.getCell('D').value = rowData.user_name;
        row.getCell('E').value = '영화예매환불';

        // 셀 스타일 적용
        ['A', 'B', 'C', 'D', 'E'].forEach((column) => {
          const cell = row.getCell(column);
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });

        // 행 커밋
        row.commit();
      });

      // 유의사항 데이터
      const notes = [
        ['유의사항 (본 유의사항은 대량이체파일 등록 전에 모두 삭제해 주십시오.)'],
        ['1) A열(필수): 셀서식을 텍스트로 지정한 후 은행코드 3자리 숫자 또는 은행(기관)명 입력'],
        ['2) B열(필수): 셀서식을 텍스트로 지정한 후 입금계좌번호 입력 (- 생략 가능)'],
        ['4) D열(필수): 예금주성명(띄어쓰기), 특수문자 입력 불가'],
        ['5) E열(필수): 입금계좌적요 (미입력 시 출금계좌의 예금주가 입금계좌 적요에 자동적용, 최대 10자리 입력 가능)'],
        ['6) F열(선택): CMS입금인 경우 CMS코드 1 입력 (당행만 가능)'],
        ['8) H열(선택): CMS입금인 경우 CMS코드 3 입력 (당행만 가능)'],
        ['9) I열(선택): 메모기능으로 최대 10자리 입력 가능'],
      ];


      // 유의사항 추가 (L1부터 T1까지 병합)
      const noteStartCol = 'L';
      const noteEndCol = 'T';

      notes.forEach((note, index) => {
        const rowIndex = index + 1;
        const mergeRange = `${noteStartCol}${rowIndex}:${noteEndCol}${rowIndex}`;
        worksheet.mergeCells(mergeRange);

        const cell = worksheet.getCell(`${noteStartCol}${rowIndex}`);
        cell.value = note[0];
        cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (rowIndex === 1) {
          // 첫 번째 행은 배경색과 굵은 글꼴 적용
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFE0' },
          };
          cell.font = { bold: true };
        }

        // 행 커밋
        worksheet.getRow(rowIndex).commit();
      });

      // 은행 목록 데이터
      const banks = [
        { bank_code: '004', bank_name: '국민' },
        { bank_code: '003', bank_name: '기업' },
        { bank_code: '011', bank_name: '농협' },
        { bank_code: '088', bank_name: '신한(구 조흥 포함)' },
        { bank_code: '071', bank_name: '우체국' },
        { bank_code: '023', bank_name: 'SC(스탠다드차타드)' },
        { bank_code: '081', bank_name: 'KEB하나(구 외환 포함)' },
        { bank_code: '027', bank_name: '한국씨티은행(구 한미)' },
        { bank_code: '020', bank_name: '우리' },
        { bank_code: '039', bank_name: '경남' },
        { bank_code: '034', bank_name: '광주' },
        { bank_code: '031', bank_name: '대구' },
        { bank_code: '055', bank_name: '도이치' },
        { bank_code: '002', bank_name: '산업' },
        { bank_code: '007', bank_name: '수협' },
        { bank_code: '037', bank_name: '전북' },
        { bank_code: '035', bank_name: '제주' },
        { bank_code: '045', bank_name: '새마을금고' },
        { bank_code: '048', bank_name: '신용협동조합' },
        { bank_code: '054', bank_name: '홍콩상하이은행(HSBC)' },
        { bank_code: '050', bank_name: '상호저축은행' },
        { bank_code: '060', bank_name: 'BOA(Bank of America)' },
        { bank_code: '057', bank_name: '제이피모간체이스' },
      ];


      // 은행 목록 헤더 추가 (유의사항과 데이터의 최대 행 아래부터 시작)
      const maxRow = Math.max(notes.length, result.length + 1);
      const banksStartRow = maxRow + 2;
      const bankHeaderRow = worksheet.getRow(banksStartRow);
      bankHeaderRow.getCell('L').value = '은행 코드';
      bankHeaderRow.getCell('M').value = '은행 이름';
      ['L', 'M'].forEach((column) => {
        const cell = bankHeaderRow.getCell(column);
        cell.font = { bold: true };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFE0' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      bankHeaderRow.commit();

      // 은행 목록 데이터 추가
      banks.forEach((bank, index) => {
        const rowIndex = banksStartRow + index + 1;
        const row = worksheet.getRow(rowIndex);
        row.getCell('L').value = bank.bank_code;
        row.getCell('M').value = bank.bank_name;

        // 셀 스타일 적용
        ['L', 'M'].forEach((column) => {
          const cell = row.getCell(column);
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });

        // 행 커밋
        row.commit();
      });

      // 엑셀 파일을 버퍼로 생성
      const buffer = await workbook.xlsx.writeBuffer();

      // 파일 다운로드
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, '환불내역_' + new Date().toLocaleDateString() + '.xlsx');
      //console.log('환불내역 버튼 클릭됨');
    } catch (error) {
      alert('다운로드 중 오류가 발생했습니다: ' + error.message);
    }
  };

  return (
    <>
      <Overlay>
        <Modal>
          <h2>환불내역 다운로드</h2>
          <br />
          <Button onClick={setOneMonthPeriod}>1개월</Button>
          <Button onClick={setThreeMonthPeriod}>3개월</Button>
          <br />
          <Label>From:</Label>
          <DateTimeInput
            type="datetime-local"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <Label>To:</Label>
          <DateTimeInput
            type="datetime-local"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
          <br />
          <Button onClick={refundExport}>다운로드</Button>
          <Button onClick={() => setIsShowDynamicPopup(false)}>취소</Button>
        </Modal>
      </Overlay>
    </>
  );
}

// Styled Components (이전 코드와 동일)
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
`;

const Label = styled.label`
  display: inline-block;
  margin: 10px;
  font-size: 14px;
`;

const DateTimeInput = styled.input`
  width: 180px;
  padding: 5px;
  font-size: 14px;
`;

const Button = styled.button`
  margin: 10px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;
