import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useApp } from '../../../AppContext';
import { PostForm } from "./PostForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHandPointer , faBan ,faClock,faUserPlus,faUserTie,faUndo,faTicketAlt,faDollarSign,faCog,faExclamationTriangle, faEdit, faTrash, faColumns, faSave, faFileExcel,faPlusSquare,faTimes, faSleigh } from '@fortawesome/free-solid-svg-icons';
import {statisticExport,order_agent,order_refund,order_cancel,order_accident,userAdd,refundExport,refundExportItem} from './clientAction';
import AddUserForm from '../../../Forms/AddUserForm';
import styled, { keyframes ,css} from "styled-components";
import {
  ControlButton,
  BoardContainer,
  BoardHeader,
  BoardTitle,
  PaginationContainer,
  PaginationButtons,
  PaginationButton,
  PostButton,
} from './styled';
import { saveAs } from 'file-saver';
import Viewer from './Viewer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ManageBoard from './ManageBoard/ManageBoard';
import SearchForm from './SearchForm';
import { BoardsTable } from "./BoardTable";
import SimpleSearchForm from "./SimpleSearchForm";
import { useNavigate } from 'react-router-dom';
import DynamicPopup from './DynamicPopup';
import dayjs from 'dayjs';

function DynamicBoard({ tableName }) {
  const { user,logout } = useApp();
  const [isFading, setIsFading] = useState(false); // 버튼 흐려지는 상태
  const [initFlag, setInitFlag] = useState(false); // 초기화 완료 여부를 확인하는 상태
  const [popupType, setPopupType] = useState('None'); // 초기값 설정
  const [popupParam, setPopupParam] = useState('None'); // 초기값 설정
  const navigate = useNavigate(); // Hook to navigate programmatically
  const location =useLocation();
  const [isOpenViewer,setIsOpenViewer] = useState(false);
  const [isShowPopup,setIsShowPopup] = useState(false); 
  const [isShowDynamicPopup,setIsShowDynamicPopup] = useState(false); 
  const [openPost,setOpenPost] = useState({});
  const [codeDisplayMap, setCodeDisplayMap] = useState({});
  const [hasTitleField, setHasTitleField] = useState(false);
  const [hasContentField, setHasContentField] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [refreshFlag , setRefreshFlag] = useState(0);
  const [orderBy, setOrderBy]=useState([]);
  const [currentAutoMode,setCurrentAutoMode]=useState(false);
  const [menuConfig,setMenuConfig] = useState({  
    use_editer:false,
    use_new:false,
    use_modify:false,
    use_delete:false,
    use_excel:false,
    use_trigger:false,
    use_view:false,
    use_upload:false,
    display_title:'title',
    display_content:'content',
    sort_order:'desc',
    post_btn_type:false,
    search_type:false,
  });
// 아이콘을 매핑하는 객체
  const iconMapping = {
    faFileExcel: faFileExcel,
    faEdit: faEdit,
    faTrash: faTrash,
    faColumns: faColumns,
    faTimes: faTimes,
    faExclamationTriangle:faExclamationTriangle,
    faDollarSign:faDollarSign,
    faTicketAlt:faTicketAlt,
    faUndo:faUndo,
    faUserTie:faUserTie,
    faUserPlus:faUserPlus,
    faBan:faBan ,
    faClock:faClock,
    faCog:faCog,
    faHandPointer :faHandPointer ,
    // 필요한 다른 아이콘들도 여기에 추가
  };
  useEffect(() => {
    //console.log('DynamicBoard에서의 user 객체:', user);
  }, [user]);
  const OrderRefundOk=(e)=>{
    //alert(JSON.stringify(e));
    //const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
    order_refund(e,refrash);
   
  }
  const popupSetData=(value,param={})=>{
    setPopupType(value);
    setPopupParam(param);
  }

  const getAutoReqOrderStatus = async () => {
    try {
      const response = await fetch(`/request/getAutoReqOrderStatus?board_id=order_list`, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch AutoReqOrder status');
      }
  
      const data = await response.json();
      return data.AutoReqOrder; // 이 값이 true 또는 false가 되어야 함
    } catch (error) {
      console.error('Error fetching AutoReqOrder status:', error);
      return false; // 기본적으로 false로 반환
    }
  };

  const toggleAutoManualMode = async () => {
    const currentStatus = await getAutoReqOrderStatus(); // 현재 상태 가져오기
    const newStatus = !currentStatus; // 현재 상태의 반대값으로 전환
  
    try {
      // 새로운 상태를 서버에 업데이트
      const response = await fetch('/request/setAutoReqOrderStatus', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ board_id: 'order_list', AutoReqOrder: newStatus }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update AutoReqOrder status');
      }
      //console.log('AutoReqOrder status updated to:', newStatus ? '자동모드' : '수동모드');

      // UI 갱신을 위해 상태 업데이트
      setCurrentAutoMode(newStatus); // 버튼 상태를 즉시 반영
      
      if (newStatus) {
        setIsFading(true); // 자동모드일 때 흐려지기 시작
      }
      else{
        setIsFading(false); // 3초 후 흐려짐 종료
      }
      setRefreshFlag(prev => prev + 1); // UI 갱신을 위해 리프레시 플래그 업데이트
    } catch (error) {
      console.error('Error updating AutoReqOrder status:', error);
    }
  };

  const buttonsData = [
    // { label: "통계", iconName: "faFileExcel", onClickHandler: () => console.log("새 버튼 1 클릭됨") },
  ];
  if(tableName === 'refund_req'){
    buttonsData.push({
      label: "환불내역",
      iconName: "faFileExcel",
      onClickHandler: () => {
        const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
        if(selectedRowsData.length >0){
          refundExportItem(selectedRowsData,refrash);
        }
        else{
          setPopupType('RefundDown');
          setIsShowDynamicPopup(true);
        }
      },
    });
  }

  if (tableName === "personal_info_mgmt") {
    buttonsData.push({
      label: "등록",
      iconName: "faUserPlus",
      onClickHandler: () => { userAdd(setIsShowPopup);},
    });
  }
  if (tableName === "coupon_set") {
    buttonsData.push({
      label: "시간설정",
      iconName: "faClock",
      onClickHandler: () => {
         // 체크박스로 선택된 행이 있는지 확인
      if (selectedRows.length === 0) {
        alert('항목을 선택하세요.');
        return;
      }
      // 선택된 행이 있으면 팝업을 표시
        setPopupType('TimeControl');
        setIsShowDynamicPopup(true);
      },
    });
    buttonsData.push({
      label: "예매제한",
      iconName: "faBan",
      onClickHandler: () => {
        setPopupType('BookingLimit');
        setIsShowDynamicPopup(true);
      },
    });
    buttonsData.push({
      label: "통계",
      iconName: "faFileExcel",
      onClickHandler: () => statisticExport(),
    });
  }
  else if (tableName === "order_list") {
    buttonsData.push({
      label: currentAutoMode ? "자동모드" : "수동모드", // 현재 상태에 따라 버튼 텍스트 변경
      iconName: currentAutoMode?"faCog":"faHandPointer",
      isFading: currentAutoMode && isFading, // 자동모드이고 흐려지는 상태일 때만 true
      onClickHandler: () => {
        const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
        toggleAutoManualMode();
        //order_agent(selectedRowsData,refrash);
      },
    });
    buttonsData.push({
      label: "예매대행",
      iconName: "faUserTie",
      onClickHandler: () => {
        const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
        order_agent(selectedRowsData,refrash);
      },
    });
    buttonsData.push({
      label: "환불요청",
      iconName: "faDollarSign",
      onClickHandler: () => {
        setPopupParam(()=>OrderRefundOk);
        setPopupType('ReqRefundView');
        setIsShowDynamicPopup(true);
      },
    });
    buttonsData.push({
      label: "취소요청",
      iconName: "faUndo",
      onClickHandler: () => {
        const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
        order_cancel(selectedRowsData,refrash);
      },
    });
    buttonsData.push({
      label: "사고접수",
      iconName: "faExclamationTriangle",
      onClickHandler: () => {
        const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
        order_accident(selectedRowsData,refrash);
      },
    });
   
  }
  const initValues = {
    save: {
      table: "테이블명",
      setColumns: ["컬럼명"],
      keyColumn: ["키컬럼명"]
    },
    update: {
      table: "테이블명",
      setColumns: ["컬럼명"],
      keyColumn: ["키컬럼명"]
    },
    delete: {
      table: "테이블명",
      keyColumn: ["키컬럼명"]
    }
  };
  
  const [searchParams, setSearchParams] = useState({});
  // const [autoSearch,setAutoSearch] = useState({ from: null, to: null });
  const [values, setValues] = useState(initValues);
  const [title, setTitle] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isManageMode, setIsManageMode] = useState(false);
  const [posts, setPosts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [totalPosts, setTotalPosts] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editedRows, setEditedRows] = useState({});
  const [saveMessage, setSaveMessage] = useState("");
  const totalPages = Math.ceil(totalPosts / postsPerPage);
// useEffect를 사용하여 location state로부터 searchParams 업데이트
// 흐려지는 애니메이션 정의
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
`;
// 애니메이션이 적용된 버튼 컴포넌트 정의
const DyControlButton = styled.button`
    width: 6.5rem;
    max-width:8.5rem;
    height: 2.2rem;
    margin:0px 2px;
    border-radius: 0.4rem;
    font-weight: bold;
    font-size: 1rem;
    border: ${(props) => (props.isAutoModeButton ? "2px solid #0073e6" : "none")}; // 텍스트 색상
    background-color: ${(props) => (props.isAutoModeButton ? "white" : "rgb(110, 10, 33)")}; // 기본 배경색
    color: ${(props) => (props.isAutoModeButton ? "black" : "white")}; // 텍스트 색상

  // 자동모드 또는 수동모드 버튼에만 애니메이션 적용
  ${(props) =>
    props.isAutoModeButton &&
    props.isFading &&
    css`
      animation: ${fadeOut} 0.3s ease-in-out infinite alternate;
    `}
      &:hover {
         background-color: ${(props) => (props.isAutoModeButton ? "white" : "rgb(140, 10, 33)")}; // 기본 배경색
      }
    `
    const resetStates = async () => {
      setFormFields([]);
      setIsAllSelected(false);
      setIsFormOpen(false);
      setIsOpenViewer(false);
      setIsEditMode(false);
      setIsManageMode(false);
      setPosts([]);
      setColumns([]);
      setCurrentPage(1);
      setTotalPosts(0);
      setSelectedRows([]);
      setEditedRows({});
      setSaveMessage("");
      setOrderBy([]);
      setInitFlag(false); // 초기화 중에는 initFlag를 false로 설정
      if (!await checkSession()) return;
    };

    
// location.state에서 검색 조건이 있을 때 처리
useEffect(() => {
  const initializeSearchParams = () => {
    if (location.state && Object.keys(location.state).length > 0) {
      setSearchParams(location.state);
    } else {
      // searchParams를 초기화하지 않음
    }
    setInitFlag(true);
  };
  initializeSearchParams();
}, [location.state]);


useEffect(() => {
  const fetchInitialAutoMode = async () => {
    const initialStatus = await getAutoReqOrderStatus();
    setCurrentAutoMode(initialStatus);
  };

  fetchInitialAutoMode();
}, []); // 컴포넌트가 마운트될 때 한 번만 실행

// useEffect(() => {
//   resetStates(false); // 검색 파라미터를 유지
// }, [tableName]);
 // useEffect를 사용하여 location state로부터 searchParams 업데이트
 const checkSession = async() => {
  const user = sessionStorage.getItem('user');
  if (!user) {
    // 세션이 없을 경우 로그인 페이지로 리다이렉트
    logout();
    navigate('/login');
    //console.log('로그아웃 상태');
    return false;
  }
  //console.log('로그인 상태');
  return true;
};

 // tableName 변경 시 상태 초기화 및 데이터 로드
 useEffect(() => {
  const resetAndInitialize = async () => {
    await resetStates();
    const formResponse = await fetch(`/request/getFormControls?table=${tableName}`);
    const formData = await formResponse.json();
    setFormFields(formData);
    setHasTitleField(formData.some(field => field.column_id === 'title'));
    setHasContentField(formData.some(field => field.column_id === 'content'));

    const orderByColumns = formData
      .filter(field => field.order_by_idx !== null)
      .sort((a, b) => a.order_by_idx - b.order_by_idx)
      .map(field => field.column_id);
    //console.log(JSON.stringify(orderByColumns));
    //console.log('Order By Columns:', orderByColumns);
    setOrderBy(orderByColumns);
    if (location.state && Object.keys(location.state).length > 0) {
      // location.state에 searchParams가 있으면 설정하고 즉시 데이터 조회
      setSearchParams(location.state);
    } else {
      // location.state에 searchParams가 없으면 초기화하고 데이터 조회
      setSearchParams({});
    }
    setInitFlag(true);
  };
  resetAndInitialize();
}, [tableName, location.state]);
  
// searchParams가 변경될 때마다 데이터를 조회하지만, 초기 로딩 시를 제외
// searchParams 또는 initFlag가 변경될 때 데이터를 조회

  useEffect(() => {
    const fetchCodeDisplayMappings = async () => {
      const newCodeDisplayMap = {};
  
      // Filter columns based on bind_type
      const columnsWithSType = columns.filter(column => (column.bind_type === 'S'|| column.bind_type === 'C'));
      const columnsWithMType = columns.filter(column => column.bind_type === 'M');
  
      // Fetch mappings for 'S' type columns
      for (const column of columnsWithSType) {
        if (column.bind_key) {
          try {
            const response = await fetch(`/request/getCodeDisplayMappings?bind_key=${encodeURIComponent(column.bind_key)}`, {
              credentials: 'include',
              headers: {
                Accept: 'application/json',
              },
              method: 'GET',
            });
  
            if (!response.ok) {
              throw new Error('Failed to fetch code display mappings');
            }
  
            const mappings = await response.json();
  
            // Add new mappings to the map
            newCodeDisplayMap[column.bind_key] = mappings[column.bind_key];
          } catch (error) {
            console.error(`Error fetching code display mappings for bind_key: ${column.bind_key}`, error);
          }
        }
      }
  
      // Fetch mappings for 'M' type columns
      for (const column of columnsWithMType) {
        if (column.bind_key) {
          try {
            const response = await fetch(`/request/getMasterDataMappings?table=${encodeURIComponent(column.bind_key)}&option=${encodeURIComponent(column.bind_option)}&display=${encodeURIComponent(column.bind_display)}`, {
              credentials: 'include',
              headers: {
                Accept: 'application/json',
              },
              method: 'GET',
            });
    
            if (!response.ok) {
              throw new Error('Failed to fetch master data mappings');
            }
    
            const mappings = await response.json();
    
            // Directly assign the mappings for 'M' type columns
            newCodeDisplayMap[column.bind_key] = mappings[column.bind_key]; // 테이블 이름이 아닌 컬럼 아이디를 사용하고 싶다면 필요에 따라 수정하세요
          } catch (error) {
            console.error(`Error fetching master data mappings for table: ${column.bind_key}`, error);
          }
        }
      }
      setCodeDisplayMap(newCodeDisplayMap); // Set all mapping data into state
    };
  
    if (columns.length > 0) { // Fetch data only if columns are not empty
      fetchCodeDisplayMappings();
    }

      // Set order by columns based on `order_by_idx` values
  if (columns.length > 0) {
   
    fetchCodeDisplayMappings(); // Fetch the mappings after setting the order
   
  }
  }, [columns]);
  useEffect(() => {
    if (initFlag) {
      fetchBoardData(searchParams, currentPage); // searchParams를 항상 포함하여 호출
    }
  }, [refreshFlag, currentPage, searchParams]);
  useEffect(() => {
    setCurrentPage(1); // 검색 조건이 변경되면 페이지를 1로 설정
  }, [searchParams]);

  // 데이터 로드 함수
const fetchBoardData = async (searchParams, currentPage) => {
  if (!await checkSession()) {
    return;
  }
  try {
    const response = await fetch(`/request/getActionInfo?board_id=${tableName}`);
    const data = await response.json();
    if (data.action_info) {
      setValues(data.action_info);
    }
  
    await getBoardData(
      currentPage,
      postsPerPage,
      tableName,
      searchParams, // 최신 searchParams를 사용
      orderBy,
      'main data fetch'
    );
  } catch (error) {
    console.error('Error fetching board data:', error);
  }
};
  useEffect(() => {
    //console.log("수정된행:", JSON.stringify(editedRows));
  }, [editedRows]);

  useEffect(() => { }, [values]);

  const toggleRowSelection = (index) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter(i => i !== index)
        : [...prevSelectedRows, index]
    );
  };

  const generateRequestBody = (actionType, tableName, values, row) => {
    let actionData = values[actionType];
    let query = '';
    let valuesArray = [];

    switch (actionType) {
      case 'save':
        const savePlaceholders = actionData.setColumns.map((col, index) => `$${index + 1}`);
        valuesArray = actionData.setColumns.map(col => row[col]);
        query = `INSERT INTO ${user.domain}.ants_${tableName} (${actionData.setColumns.join(', ')}) VALUES (${savePlaceholders.join(', ')})`;
        break;
      case 'update':
         // Calculate the placeholders for the SET clause
          const updatePlaceholders = Object.keys(row).map((key, index) => `"${key}" = $${index + 1}`);

          // The values for the SET clause
          valuesArray = Object.values(row);

          // Calculate the placeholders for the WHERE clause, accounting for the SET clause length
          const wherePlaceholders = actionData.keyColumn.map((col, index) => `${col} = $${index + 1}`);

          // Add the keyColumn values (for WHERE clause) to the valuesArray after the SET clause values
          valuesArray = [...valuesArray];
          query = `UPDATE ${user.domain}.ants_${tableName} SET ${updatePlaceholders.join(', ')} WHERE ${wherePlaceholders.join(' AND ')}`;
        break;
      case 'delete':
        valuesArray = actionData.keyColumn.map(col => row[col]);
        query = `DELETE FROM ${user.domain}.ants_${tableName} WHERE ${actionData.keyColumn.map((col, index) => `${col} = $${index + 1}`).join(' AND ')}`;
        break;
      default:
        break;
    }
    return { query, values: valuesArray };
  };

  const handleSaveClick = async (actionType, tableName, values, rows) => {
    for (let i = 0; i < rows.length; i++) {
      const requestBody = generateRequestBody(actionType, tableName, values, rows[i]);
      await fetch('/request/saveAction', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
    }
    //setSaveMessage("반영 완료");
    //setTimeout(() => setSaveMessage(""), 3000);
  };
 

  const getBoardData = async (page = 1, limit = 10, table, searchParams={},orderByColumns,callName) => {
    if(!await checkSession()) {
      return ;
    };
    try {
      console.log(JSON.stringify(searchParams));
      //console.log(callName);
      const adjustedSearchParams = { ...searchParams };

      // 'from'이 존재하고 값이 유효하면 추가
      if (searchParams?.from) {
        adjustedSearchParams.from = searchParams.from;
      }
      
      if (searchParams?.to) {
        adjustedSearchParams.to = searchParams.to;
      }
      const filteredParams = Object.entries(adjustedSearchParams)
      .filter(([key, value]) => value !== undefined && value !== null && value !== '')
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
      const queryParams = new URLSearchParams({
        page,
        limit,
        table,
        ...filteredParams,
        orderby: Array.isArray(orderByColumns) && orderByColumns.length > 0 ? orderByColumns.join(',') : '',
        sort: menuConfig.sort_order,
      }).toString();

    //console.log('쿼리 파라미터:', queryParams);
      //console.log(orderBy);
      //order by
    //console.log('검색조건:'+JSON.stringify(queryParams));
      const response = await fetch(`/request/dynamicboardList?${queryParams}`, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
        method: 'GET',
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
     
      setPosts(data.boardData[0].sampleRows || []);
      setColumns(data.boardData[0].columns || []);
      setTitle(data.boardData[0].board_title);
      setMenuConfig({
        use_new:data.boardData[0].use_new,
        use_editer:data.boardData[0].use_editer,
        use_modify:data.boardData[0].use_modify,
        use_delete:data.boardData[0].use_delete,
        use_excel:data.boardData[0].use_excel,
        use_trigger:data.boardData[0].use_trigger,
        use_view:data.boardData[0].use_view,
        use_upload:data.boardData[0].use_upload,
        display_title:data.boardData[0].display_title,
        display_content:data.boardData[0].display_content,
        sort_order:data.boardData[0].sort_order,
        post_btn_type:data.boardData[0].post_btn_type,
        search_type:data.boardData[0].search_type,
       });
      setTotalPosts(data.total);
    } catch (error) {
      alert('조회오류 ' +'['+callName+']' + error+'['+orderByColumns+']');
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (initFlag) {
      fetchBoardData(searchParams,currentPage);
    }
  }, [searchParams]);

  const getPageNumbers = () => {
    const startPage = Math.max(currentPage - 5, 1);
    const endPage = Math.min(startPage + 9, totalPages);
    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const handleUpdate = async(tableName, values, posts) => {
    const selectedRowsData = selectedRows.map(rowIndex => editedRows[rowIndex] || posts[rowIndex]);
    await handleSaveClick('update', tableName, values, selectedRowsData);
    setEditedRows({});
    setSelectedRows([]);
    setIsEditMode(false);
    //console.log('수정 버튼 클릭됨');
    //fetchBoardData();
    setRefreshFlag(prev => prev + 1);
  };

  const handleDelete = async (tableName, values, posts) => {
    // 삭제 확인 메시지
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return; // 사용자가 '아니오'를 선택한 경우 함수 종료
    }
  
    const selectedRowsData = selectedRows.map(rowIndex => posts[rowIndex]);
    await handleSaveClick('delete', tableName, values, selectedRowsData);
    setIsEditMode(false);
    //console.log('삭제 버튼 클릭됨');
    //fetchBoardData();
    setRefreshFlag(prev => prev + 1);
  };

  const getAllBoardData = async (page = 1, limit = 10, table, searchParams,orderByColumns,callName) => {
    if(!await checkSession()) {
      return ;
    };
    try {
      const formattedFrom =
        searchParams?.from instanceof Date && !isNaN(searchParams.from)
          ? searchParams?.from.toISOString().split('T')[0]
          : '';
      const formattedTo =
        searchParams?.to instanceof Date && !isNaN(searchParams.to)
          ? searchParams?.to.toISOString().split('T')[0]
          : '';
          
      const queryParams = new URLSearchParams({
        page,
        limit,
        table,
        ...searchParams,
        from: formattedFrom,
        to: formattedTo,
        orderby: Array.isArray(orderByColumns) && orderByColumns.length > 0 ? orderByColumns.join(',') : '',
        sort:menuConfig.sort_order
      }).toString();
   
      //console.log(orderBy);
      //order by
      const response = await fetch(`/request/dynamicboardList?${queryParams}`, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
        method: 'GET',
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
     const data = await response.json();
     alert(JSON.stringify(data.boardData[0].sampleRows));
     return data.boardData[0].sampleRows;
      
    } catch (error) {
      alert('조회오류 ' +'['+callName+']' + error+'['+orderByColumns+']');
      console.error('Error fetching data:', error);
    }
  };
  const handleExport = async() => {
    let selectedRowsData = [];
    
    if(selectedRows.length === 0){
      
      selectedRowsData = await getAllBoardData(1,totalPosts,tableName, searchParams,orderBy,'currentPage, tableName, refreshFlag,saveMessage');
    }
    else{
      selectedRowsData = selectedRows.map(rowIndex => editedRows[rowIndex] || posts[rowIndex])
    }
    const csvData = [
      columns.map(col => col.column_title), // 컬럼 제목 포함
      ...selectedRowsData.map(row => columns.map(col => row[col.column_id])) // 데이터 매핑
    ];
    const BOM = '\uFEFF';
    const csvContent = BOM+csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${tableName}_export.csv`);
    //console.log('엑셀 내보내기 버튼 클릭됨');
  };

  const handleSaveJson = () => {
    setIsEditMode(false);
    //console.log('JSON 저장 버튼 클릭됨');
  };

  const handleRowChange = (index, column, value, board) => {
    let actionData = values['update'];
    
    // keyColumn에 있는 속성명을 사용해 board에서 값을 추출
    const keyColumnValues = actionData.keyColumn.reduce((acc, key) => {
      acc[key] = board[key]; // board 객체에서 keyColumn 값 추출
      return acc;
    }, {});
    // 고유한 keyColumn 값을 문자열로 연결하여 식별자로 사용
    // 날짜 및 시간 처리
    let adjustedValue = value;
    // column이 DateBox, TimeBox, DateTimeBox 중 하나일 경우 dayjs로 값 처리
    if (column.control_type === "DateBox" || column.control_type === "TimeBox" || column.control_type === "DateTimeBox") {
      const dateObj = dayjs(value);
      if (column.control_type === "DateBox") {
        adjustedValue = dateObj.format('YYYY-MM-DD'); // 날짜는 YYYY-MM-DD 형식으로 저장
      } else if (column.control_type === "TimeBox") {
        adjustedValue = dateObj.format('HH:mm'); // 시간은 HH:mm 형식으로 저장
      } else if (column.control_type === "DateTimeBox") {
        adjustedValue = dateObj.toISOString(); // 날짜와 시간은 ISO 형식으로 저장
      }
    }
    // 상태 업데이트
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [index]: {
        ...prevEditedRows[index],
        ...keyColumnValues, // keyColumn 값 추가
        [column]: adjustedValue // 수정된 값 추가
      }
    }));
  };

  const handleSaveRow = (index) => {
    setIsEditMode(false);
    setIsManageMode(false);
    setPosts((prevPosts) => {
      const newPosts = [...prevPosts];
      newPosts[index] = { ...newPosts[index], ...editedRows[index] }; // 변경 사항 병합
      return newPosts;
    });

    setEditedRows((prevEditedRows) => {
      const newEditedRows = { ...prevEditedRows };
      delete newEditedRows[index];
      return newEditedRows;
    });
  };

  const handleSearchChange = (field, value) => {
    console.log('선택된시간'+value);
    setSearchParams((prevParams) => ({
      ...prevParams,
      [field]: value
    }));
  };
  const refrash=()=>{
    setSelectedRows([]);
    setRefreshFlag(prev => prev + 1);
  }
  return (
    <BoardContainer>
        {!menuConfig.post_btn_type && menuConfig.use_new && <PostButton onClick={() => setIsFormOpen(!isFormOpen)}>
            <FontAwesomeIcon icon={faPlusSquare} /> 신  규  등  록
        </PostButton>}
        <hr/>
      <div>
        {isFormOpen ? (
          <PostForm
            handleSearchChange={handleSearchChange}
            formFields={formFields}
            hasTitleField={hasTitleField}
            hasContentField={hasContentField}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            getBoardData={getBoardData}
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            tableName={tableName}
            refreshFlag={refreshFlag}
            setRefreshFlag={setRefreshFlag}
            closeForm={() => setIsFormOpen(false)}
            menuConfig={menuConfig}
            setFormFields={setFormFields}
          />):(<></>)
        }
        <BoardHeader>
          <BoardTitle>{title}</BoardTitle>
            <div className="edit-buttons" style={{alignContent:'center'}}>
               {/* 동적으로 버튼을 추가 */}
               {buttonsData.map((button, index) => (
                  <DyControlButton
                    key={index}
                    onClick={button.onClickHandler}
                     isFading={button.isFading} // 자동모드일 때만 깜빡임 적용
                     isAutoModeButton={button.label === "자동모드" || button.label === "수동모드"} // 자동모드와 수동모드 버튼에만 적용
                  >
                    <FontAwesomeIcon icon={iconMapping[button.iconName]} /> {button.label}
                  </DyControlButton>
                ))}
              {menuConfig.post_btn_type && menuConfig.use_new && <ControlButton onClick={() => setIsFormOpen(!isFormOpen)}>
                <FontAwesomeIcon icon={faPlusSquare} /> 등록
              </ControlButton>}
              {menuConfig.use_modify &&<ControlButton onClick={() => handleUpdate(tableName, values, posts)}>
                <FontAwesomeIcon icon={faEdit} /> 수정
              </ControlButton>}
              {menuConfig.use_delete && <ControlButton onClick={() => handleDelete(tableName, values, posts)}>
                <FontAwesomeIcon icon={faTrash} /> 삭제
              </ControlButton>}
              {menuConfig.use_excel &&<ControlButton onClick={handleExport}>
                <FontAwesomeIcon icon={faFileExcel} /> 엑셀
              </ControlButton>}
              <ControlButton selected={isManageMode}  onClick={() => setIsManageMode(!isManageMode)}>
                <FontAwesomeIcon icon={faColumns} /> 관리
              </ControlButton>
            </div>
          {/* )} */}
        </BoardHeader>
        {isManageMode && (
          <ManageBoard
            setMenuConfig={setMenuConfig}
            menuConfig={menuConfig}
            handleSaveJson={handleSaveJson}
            tableName={tableName}
            setValues={setValues}
            values={values}
            setIsManageMode={setIsManageMode}
          />
        )}
        {/* <ToolbarContainer></ToolbarContainer> */}
        {menuConfig.search_type ? <SearchForm
          orderBy={orderBy}
          handleSearchChange={handleSearchChange}
          formFields={formFields}
          hasTitleField={hasTitleField}
          hasContentField={hasContentField}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          getBoardData={getBoardData}
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          tableName={tableName}
        />:<SimpleSearchForm
        orderBy={orderBy}
        handleSearchChange={handleSearchChange}
        formFields={formFields}
        hasTitleField={hasTitleField}
        hasContentField={hasContentField}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        getBoardData={getBoardData}
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        tableName={tableName}
      />}
        {isOpenViewer && menuConfig.use_view &&
        <Viewer
          board_id={tableName}
          keyColumn={values['update'].keyColumn}
          post_id={openPost[values['update'].keyColumn]}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          board={openPost}
          setPosts={setPosts}
          columns={columns}
          isEditMode={isEditMode}
          toggleRowSelection={toggleRowSelection}
          editedRows={editedRows}
          handleRowChange={handleRowChange}
          handleSaveRow={handleSaveRow}
          saveMessage={saveMessage}
          codeDisplayMap={codeDisplayMap} // codeDisplayMap을 전달
          isManageMode={isManageMode}
          menuConfig={menuConfig}
          onRefresh={() => setRefreshFlag(prev => prev + 1)}
          setIsOpenViewer = {setIsOpenViewer}
        />}
        <div style={{display:'flex',overflowX:'auto'}}>
        <BoardsTable
          tableName={tableName}
          searchParams={searchParams}
          resetStates={resetStates}
          setOpenPost={setOpenPost}
          setIsOpenViewer={setIsOpenViewer}
          isOpenViewer={isOpenViewer}
          isAllSelected={isAllSelected}
          setIsAllSelected={setIsAllSelected}
          boards={posts}
          setPosts={setPosts}
          columns={columns}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isEditMode={isEditMode}
          toggleRowSelection={toggleRowSelection}
          editedRows={editedRows}
          handleRowChange={handleRowChange}
          handleSaveRow={handleSaveRow}
          saveMessage={saveMessage}
          codeDisplayMap={codeDisplayMap} // codeDisplayMap을 전달
          isManageMode={isManageMode}
          refreshFlag={refreshFlag}
          //setRefreshFlag={setRefreshFlag}
          onRefresh={() => setRefreshFlag(prev => prev + 1)}
          setIsShowDynamicPopup={setIsShowDynamicPopup}
          setPopupType={popupSetData}
        />
        </div>
        <PaginationContainer>
          <span>[ {totalPosts} ] - Page {currentPage} of {totalPages} </span>
          <PaginationButtons>
            <PaginationButton
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              이전
            </PaginationButton>
            {getPageNumbers().map((page) => (
              <PaginationButton
                key={page}
                onClick={() => setCurrentPage(page)}
                disabled={currentPage === page}
              >
                {page}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              다음
            </PaginationButton>
          </PaginationButtons>
        </PaginationContainer>
      </div>
      {isShowPopup && <AddUserForm setIsShowPopup={setIsShowPopup} setRefreshFlag={setRefreshFlag} onRefresh={() => setRefreshFlag(prev => prev + 1)}/>}
      {isShowDynamicPopup &&<DynamicPopup popupParam={popupParam} rows={selectedRows.map(rowIndex => posts[rowIndex])} type={popupType} setIsShowDynamicPopup={setIsShowDynamicPopup} isShowDynamicPopup={isShowDynamicPopup} 
      onRefresh={() => setRefreshFlag(prev => prev + 1)}/>}
    </BoardContainer>
  );
}

export { DynamicBoard };