import React, { useEffect } from 'react';
import BookingLimit from '../../../../Forms/BookingLimit';
import TimeControl from '../../../../Forms/TimeControl';
import RefundDown from '../../../../Forms/RefundDown';
import VerifyCouponView from '../../../../Forms/VerifyCouponView';
import UserInfoView from '../../../../Forms/UserInfoView';
import ReqRefundView from '../../../../Forms/ReqRefundView';

export default function DynamicPopup({ popupParam,type, rows, setIsShowDynamicPopup,isShowDynamicPopup, onRefresh }) {
  // 팝업 닫힐 때 refrash 함수 실행

  switch (type) {
    case 'None':
      return <></>;
    case 'TimeControl':
      return <><TimeControl setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh} /></>;
    case 'BookingLimit':
      return <><BookingLimit setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh} /></>;
    case 'RefundDown':
      return <><RefundDown setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh} /></>;
    case 'UserInfoView':
      return <><UserInfoView setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh} /></>;
    case 'VerifyCouponView':
      return <><VerifyCouponView setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh}/></>;
    case 'ReqRefundView':
      return <><ReqRefundView setIsShowDynamicPopup={setIsShowDynamicPopup} rows={rows} isShowDynamicPopup={isShowDynamicPopup} popupParam={popupParam} onRefresh={onRefresh} /></>;
      
    default:
      return <></>;
  }
};