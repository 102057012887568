/**
 * 작성일 : 2024-11-12
 * 작성자 : 양다빈
 * 유저 세션 저장 및 확인
 */

export const setSessionFunc = async (data) => {
  console.log(`***** setSessionFunc start *****`);
  try {
    const response = await fetch("/user/set_session", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: data,
      }),
    });

    console.log(response);
    const returnData = await response.json();
    console.log(`***** setSessionFunc *****`);
    console.log(returnData);
    console.log(`***** setSessionFunc *****`);
    if (response.status == 200) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`setSessionFunc Error : ${e}`);
  }
};

export const getSessionFunc = async (id) => {
  console.log(id);
  try {
    const response = await fetch("/user/get_session", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    });

    const returnData = await response.json();
    console.log(`***** getSessionFunc *****`);
    console.log(returnData);
    console.log(`***** getSessionFunc *****`);
    if (response.status == 200) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`getSessionFunc Error : ${e}`);
  }
};

export const delSessionFunc = async (id) => {
  try {
    const response = await fetch("/user/del_session", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    });

    const returnData = await response.json();
    //console.log(`***** delSessionFunc *****`);
    //console.log(returnData);
    //console.log(`***** delSessionFunc *****`);
    if (response.status == 200) {
      return returnData;
    } else {
      // 로그인 실패 처리
      if (response.status === 401) {
      }
    }
  } catch (e) {
    console.log(`getSessionFunc Error : ${e}`);
  }
};
