import React, { useEffect, useState } from "react";
import styled from "styled-components";

const VerifyCouponView = ({ setIsShowDynamicPopup, isShowDynamicPopup, rows ,popupParam}) => {
  const [couponCode, setCouponCode] = useState("");
  const [Record, setRecord] = useState(popupParam);
  useEffect(()=>{
    setRecord(popupParam)}
    ,[popupParam])
  const handleChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleVerify = async () => {
    //alert(JSON.stringify(rows)+couponCode);
    try {
      const response = await fetch("https://cinema.skynet.re.kr/request/crm_registerCoupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rows:Record,coupon_code:couponCode }),
      });
        
      if (response.ok) {
        const data = await response.text();
        alert("쿠폰이 인증되었습니다: " + JSON.stringify(data));
        setIsShowDynamicPopup(false);
      } else {
        alert("쿠폰 인증에 실패했습니다.");
      }
    } catch (error) {
      alert("에러가 발생했습니다: " + error.message);
    }
  };

  return (
    <PopupContainer>
      <PopupContent>
        <h2>쿠폰 인증</h2>
        <Form>
            <input
              type="text"
              value={couponCode}
              onChange={handleChange}
              placeholder="쿠폰 번호 입력"
            />
        </Form>
        <ButtonContainer>
          <button onClick={() => handleVerify(isShowDynamicPopup)}>인증하기</button>
          <button onClick={() => setIsShowDynamicPopup(false)}>닫기</button>
        </ButtonContainer>
      </PopupContent>
    </PopupContainer>
  );
};

// Styled-components for styling the popup
const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
`;

const Form = styled.div`
  margin-top: 20px;

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
  }

  input {
    width: calc(100% - 8px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

export default VerifyCouponView;